<template>
  <MainLayout>
    <div class="downloadcenter">
      <div class="download_box">
        <div class="download_inner d3">
          <div class="left">
            <div class="txt1">3D直播间客户端</div>
            <div class="txt2">主要用处：用于3D数字人直播</div>
            <div class="txt2">面向人群：幻腾科技3D数字人直播会员</div>
            <div class="txt2">
              工具类型：电脑端工具，适用于电脑Windows10及以上系统
            </div>
            <div class="txt2">最低配置：Win10+i5 12400F+显卡1660+16G内存以上</div>
            <!-- <div class="txt2">{{ $t("live.download_txt6") }}</div> -->
            <div class="download_bt">
              <img
                class="icon"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAAAXNSR0IArs4c6QAAAKtJREFUSEvtlkEKwjAQRV9cFNRtdy7cqT2KuLFHsffyQl7my4CVIQ0y1OoqA9kNLz8/GX4SX5akFbAHOuDkVpeibElNBhhhB2Bd4kzgktoXxCvpgQtwj4qxviTp5hQZ0OB5bYDzHLgCaiq8aFK1ZVlbjoGn+AC2wC7Q+26xIfopvA7R5DrM82rL/20ZfO4tGhb5WQoxZ+l0nRVz0XHOAtrnazygo5uNfZ++Fk+4AHHhYrySqwAAAABJRU5ErkJggg=="
              />立即下载
            </div>
          </div>
          <img
            src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/local.0ee24f21.png"
            class="right"
          />
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue"
export default {
  name: "downloadCenter",
  components: {
    MainLayout,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@keyframes waves-4c2a1545 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 11600px;
  }
}
.downloadcenter {
  width: 100%;
  min-height: 100%;
  // height: calc(100vh - 200px);
  background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/bg1.0e46b7b3.png)
    no-repeat;
  background-size: 100% 300px;
  background-position: 0 0;
  position: relative;

  // &::before {
  //   display: block;
  //   content: "";
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/bg2.6dddfd5e.png)
  //     0 bottom repeat-x;
  //   background-size: 100%;
  //   animation: waves-4c2a1545 200s linear infinite;
  //   z-index: -1;
  // }
}

.download_box {
  height: 100%;
  width: 100%;
  position: relative;
  .download_inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 126px;
    // padding-top: 200px;
    .left {
      padding-top: 100px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .txt1 {
        font-size: 34px;
        font-weight: 600;
        color: #222831;
        margin-bottom: 24px;
      }
      .txt2 {
        font-size: 14px;
        font-weight: 400;
        color: #222831;
        margin-bottom: 8px;
      }
      .download_bt {
        height: 56px;
        background: #006eff;
        border-radius: 28px;
        padding: 0 42px;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 80px;
        .icon {
          width: 30px;
          margin-right: 10px;
        }
      }
    }

    .right {
      width: 781px;
    }
  }
}
</style>

<template>
  <div class="moreNeed_warpper">
    <div class="content-title">多种直播方式，满足客户各类直播需求</div>
    <div class="tab_warpper">
      <div
        class="tab_inner"
        style="height: 48px; background: rgb(243, 247, 253); line-height: 40px"
      >
        <span
          class="tab_inner_span cursor-p"
          :class="{ activeStyle: activeTab === 'autoPlay' }"
          @click="changeTab('autoPlay')"
        >
          24h自动播
        </span>
        <span
          class="tab_inner_span cursor-p"
          :class="{ activeStyle: activeTab === 'live' }"
          @click="changeTab('live')"
        >
          真人直播
        </span>
      </div>
    </div>

    <div class="inner_wrapper">
      <div class="inner_wrapper">
        <div class="three_box clearfix">
          <div class="box_left">
            <div class="list">
              <div class="list_item clearfix">
                <div class="item_right">
                  <h3>真人直播</h3>
                  <p class="item_right_subTilte">
                    直播运营可语音实时驱动数字人，主播话术可随直播效果切换，低成本实现超值效果。
                  </p>
                  <div>
                    <p class="ulArr_contain">
                      <img
                        class="ulArr_image"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAtVJREFUWEftl89LVFEUxz9nHMtokbaJ2lb/RJuZxH5TLipKCCkKalHzjIiMqGki0NRx/LGIiAihQCwkaNUiKDCJKEhkSpQkyVDDdPpNEZ5485zK+XHnzSjiwru933PO555z7nn3CQtsyQLjYREoW0UWM7SYoWwZiO8HdQkxQigHERRoo5ggIfk1/z3kwHSi7JgBL9TSLGfnF+iaFhLlDlCeJpOjtMjq+QPq0AK6aAf2pC2rMEKzrJkfoKB6iHELpSJjj6WUTFWwWIcXIcwAInazzX45MDdRKg0wD1hLOQH56WTI0lUoncCGaaNuvOynUd7Nisg+ZBXXUQ4bYB7iYScR+WFrEkAdKHuTjN5QgI+IvM8bytKrKMcM9o9ZyXYuyveExgEKaAxYkcawn6X4qJfRnKEC2gKcMNh1U8IWQvL1f00iQ/0o6zN0/2sK8dMgH1xDWdqAcsqgf0YJmwjJ52RNAqgSpc3goJflbKRGPmaFsrQGpdqge4GHMprErkrK+nftq/QSU5w3NN9LllFKrUxm1Fhqfw4uGGB6KKCUiExk0sycQ5bWopwxQD2niDKuyKcUTZWeY4rLBtsoXvyEZdyU5dTBaGkjykmD0VOK2EydfPmrsfQ0Sp3Bpg/BT7OMZSt5+kltaSvKccNpuyhkKw3yjYBaQJNBO4Dgo0lGssHY++mBnIFmz5CjhkCPEO4zRdigGYyPjToZdgOTGcjecaBuoBxy6yxJN0QhPsIylIu9+ePqfIfaUA7k4hQYxhMv02COdi5+g+xnwxNuo+xz5dx+Rig+WmTAlT5J5O75EVQvMdpRdhuDCGPTV7svHxhzDyV7tF97r7iLsittMGEcD34iEs0XJjcgW+28h++hbJsRVJiIT+BG6ZkNTO5ADlQRk7QCR+LBhSgeKohI72xh8gNKRK3WEn5TTD1v5+x1mXEwzsVR8/Th7pbl6Twfs0WgbFlbcBn6A+Hs1CUGbdDxAAAAAElFTkSuQmCC"
                        alt="rightIcon"
                      /><span class="ulArr_span"
                        >真人语音实时驱动，开启高效直播</span
                      >
                    </p>
                    <p class="ulArr_contain">
                      <img
                        class="ulArr_image"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAtVJREFUWEftl89LVFEUxz9nHMtokbaJ2lb/RJuZxH5TLipKCCkKalHzjIiMqGki0NRx/LGIiAihQCwkaNUiKDCJKEhkSpQkyVDDdPpNEZ5485zK+XHnzSjiwru933PO555z7nn3CQtsyQLjYREoW0UWM7SYoWwZiO8HdQkxQigHERRoo5ggIfk1/z3kwHSi7JgBL9TSLGfnF+iaFhLlDlCeJpOjtMjq+QPq0AK6aAf2pC2rMEKzrJkfoKB6iHELpSJjj6WUTFWwWIcXIcwAInazzX45MDdRKg0wD1hLOQH56WTI0lUoncCGaaNuvOynUd7Nisg+ZBXXUQ4bYB7iYScR+WFrEkAdKHuTjN5QgI+IvM8bytKrKMcM9o9ZyXYuyveExgEKaAxYkcawn6X4qJfRnKEC2gKcMNh1U8IWQvL1f00iQ/0o6zN0/2sK8dMgH1xDWdqAcsqgf0YJmwjJ52RNAqgSpc3goJflbKRGPmaFsrQGpdqge4GHMprErkrK+nftq/QSU5w3NN9LllFKrUxm1Fhqfw4uGGB6KKCUiExk0sycQ5bWopwxQD2niDKuyKcUTZWeY4rLBtsoXvyEZdyU5dTBaGkjykmD0VOK2EydfPmrsfQ0Sp3Bpg/BT7OMZSt5+kltaSvKccNpuyhkKw3yjYBaQJNBO4Dgo0lGssHY++mBnIFmz5CjhkCPEO4zRdigGYyPjToZdgOTGcjecaBuoBxy6yxJN0QhPsIylIu9+ePqfIfaUA7k4hQYxhMv02COdi5+g+xnwxNuo+xz5dx+Rig+WmTAlT5J5O75EVQvMdpRdhuDCGPTV7svHxhzDyV7tF97r7iLsittMGEcD34iEs0XJjcgW+28h++hbJsRVJiIT+BG6ZkNTO5ADlQRk7QCR+LBhSgeKohI72xh8gNKRK3WEn5TTD1v5+x1mXEwzsVR8/Th7pbl6Twfs0WgbFlbcBn6A+Hs1CUGbdDxAAAAAElFTkSuQmCC"
                        alt="rightIcon"
                      /><span class="ulArr_span"
                        >真人动补设备驱动，开启3D虚拟直播</span
                      >
                    </p>
                    <p class="ulArr_contain">
                      <img
                        class="ulArr_image"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAtVJREFUWEftl89LVFEUxz9nHMtokbaJ2lb/RJuZxH5TLipKCCkKalHzjIiMqGki0NRx/LGIiAihQCwkaNUiKDCJKEhkSpQkyVDDdPpNEZ5485zK+XHnzSjiwru933PO555z7nn3CQtsyQLjYREoW0UWM7SYoWwZiO8HdQkxQigHERRoo5ggIfk1/z3kwHSi7JgBL9TSLGfnF+iaFhLlDlCeJpOjtMjq+QPq0AK6aAf2pC2rMEKzrJkfoKB6iHELpSJjj6WUTFWwWIcXIcwAInazzX45MDdRKg0wD1hLOQH56WTI0lUoncCGaaNuvOynUd7Nisg+ZBXXUQ4bYB7iYScR+WFrEkAdKHuTjN5QgI+IvM8bytKrKMcM9o9ZyXYuyveExgEKaAxYkcawn6X4qJfRnKEC2gKcMNh1U8IWQvL1f00iQ/0o6zN0/2sK8dMgH1xDWdqAcsqgf0YJmwjJ52RNAqgSpc3goJflbKRGPmaFsrQGpdqge4GHMprErkrK+nftq/QSU5w3NN9LllFKrUxm1Fhqfw4uGGB6KKCUiExk0sycQ5bWopwxQD2niDKuyKcUTZWeY4rLBtsoXvyEZdyU5dTBaGkjykmD0VOK2EydfPmrsfQ0Sp3Bpg/BT7OMZSt5+kltaSvKccNpuyhkKw3yjYBaQJNBO4Dgo0lGssHY++mBnIFmz5CjhkCPEO4zRdigGYyPjToZdgOTGcjecaBuoBxy6yxJN0QhPsIylIu9+ePqfIfaUA7k4hQYxhMv02COdi5+g+xnwxNuo+xz5dx+Rig+WmTAlT5J5O75EVQvMdpRdhuDCGPTV7svHxhzDyV7tF97r7iLsittMGEcD34iEs0XJjcgW+28h++hbJsRVJiIT+BG6ZkNTO5ADlQRk7QCR+LBhSgeKohI72xh8gNKRK3WEn5TTD1v5+x1mXEwzsVR8/Th7pbl6Twfs0WgbFlbcBn6A+Hs1CUGbdDxAAAAAElFTkSuQmCC"
                        alt="rightIcon"
                      /><span class="ulArr_span"
                        >上千个数字人资产可选、可DIY</span
                      >
                    </p>
                    <p class="ulArr_contain">
                      <img
                        class="ulArr_image"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAtVJREFUWEftl89LVFEUxz9nHMtokbaJ2lb/RJuZxH5TLipKCCkKalHzjIiMqGki0NRx/LGIiAihQCwkaNUiKDCJKEhkSpQkyVDDdPpNEZ5485zK+XHnzSjiwru933PO555z7nn3CQtsyQLjYREoW0UWM7SYoWwZiO8HdQkxQigHERRoo5ggIfk1/z3kwHSi7JgBL9TSLGfnF+iaFhLlDlCeJpOjtMjq+QPq0AK6aAf2pC2rMEKzrJkfoKB6iHELpSJjj6WUTFWwWIcXIcwAInazzX45MDdRKg0wD1hLOQH56WTI0lUoncCGaaNuvOynUd7Nisg+ZBXXUQ4bYB7iYScR+WFrEkAdKHuTjN5QgI+IvM8bytKrKMcM9o9ZyXYuyveExgEKaAxYkcawn6X4qJfRnKEC2gKcMNh1U8IWQvL1f00iQ/0o6zN0/2sK8dMgH1xDWdqAcsqgf0YJmwjJ52RNAqgSpc3goJflbKRGPmaFsrQGpdqge4GHMprErkrK+nftq/QSU5w3NN9LllFKrUxm1Fhqfw4uGGB6KKCUiExk0sycQ5bWopwxQD2niDKuyKcUTZWeY4rLBtsoXvyEZdyU5dTBaGkjykmD0VOK2EydfPmrsfQ0Sp3Bpg/BT7OMZSt5+kltaSvKccNpuyhkKw3yjYBaQJNBO4Dgo0lGssHY++mBnIFmz5CjhkCPEO4zRdigGYyPjToZdgOTGcjecaBuoBxy6yxJN0QhPsIylIu9+ePqfIfaUA7k4hQYxhMv02COdi5+g+xnwxNuo+xz5dx+Rig+WmTAlT5J5O75EVQvMdpRdhuDCGPTV7svHxhzDyV7tF97r7iLsittMGEcD34iEs0XJjcgW+28h++hbJsRVJiIT+BG6ZkNTO5ADlQRk7QCR+LBhSgeKohI72xh8gNKRK3WEn5TTD1v5+x1mXEwzsVR8/Th7pbl6Twfs0WgbFlbcBn6A+Hs1CUGbdDxAAAAAElFTkSuQmCC"
                        alt="rightIcon"
                      /><span class="ulArr_span">完善的售后教学，快速上手</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box_right animate__animated animate__fadeInLeft">
            <div class="swiper-slide">
              <div class="commVideo_warpper">
                <video
                  preload="none"
                  src="https://mobvoi-digitalhuman-public.weta365.com/1689112347211337728.mp4"
                  controls=""
                  poster="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/24andRealCover.41ae9d1e.png"
                ></video>
                <div class="step1_video_bt"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreNeed",
  data() {
    return {
      activeTab: "autoPlay",
    }
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<style lang="scss" scoped>
.moreNeed_warpper {
  height: 846px;
  min-width: 1200px;
  background: #ffffff;
}

.content-title {
  font-size: 48px;
  line-height: 60px;
  padding: 80px 0 32px;
  text-align: center;
  font-weight: 700;
  color: #222831;
}

.tab_warpper {
  text-align: center;

  .tab_inner {
    padding: 0 4px;
    display: inline-block;
    margin: 0 auto 60px;
    border-radius: 29px;
    font-size: 16px;
    font-weight: 600;
    color: #848c96;

    .tab_inner_span {
      display: inline-block;
      border-radius: 29px;
      height: calc(100% - 8px);
      margin-top: 4px;
      text-align: center;
      padding: 0 40px;
    }
    .activeStyle {
      background: #365074 !important;
      color: #fff !important;
    }
  }
  .cursor-p {
    cursor: pointer;
  }
}

.inner_wrapper {
  margin: 0 auto;
  width: 1200px;
  .three_box {
    position: relative;
    .box_left {
      width: 420px;
      float: left;
      padding-top: 57px;
      .list {
        padding-bottom: 64px;

        .list_item {
          .item_right {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;

            .ulArr_contain {
              margin-bottom: 8px;
            }
            .ulArr_image {
              height: 18px;
              width: 18px;
              vertical-align: middle;
              margin-right: 16px;
            }
            .ulArr_span {
              font-size: 16px;
              font-weight: 400;
              color: #222831;
              line-height: 24px;
            }
          }
          .item_right_subTilte {
            margin-bottom: 32px;
            width: 360px;
            height: 48px;
            font-size: 16px;
            color: #848c96;
            line-height: 24px;
          }
        }
      }
    }

    .box_right {
      width: 740px;
      height: 462px;
      position: relative;
      float: right;
      overflow: hidden;
      .swiper-slide {
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      .commVideo_warpper {
        width: 100%;
        height: 100%;
        position: relative;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .clearfix {
    zoom: 1;
  }
  .clearfix:after {
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}
</style>

import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/home/index.vue"
import makehome from "../views/makeHome/index.vue"
import broadcastCenter from "../views/broadcastCenter/index.vue"
import memberIndex from "../views/memberIndex/index.vue"
import downloadCenter from "../views/helpCenter/downloadCenter/index.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // 视频制作
  {
    path: "/makehome",
    name: "makehome",
    component: makehome,
    meta: {
      title: "视频制作",
    },
    // component: () => import("../views/makeHome/index.vue"),
  },
  // 直播中心
  {
    path: "/broadcastCenter",
    name: "broadcastCenter",
    component: broadcastCenter,
    meta: {
      title: "直播中心",
    },
    // component: () => import("../views/broadcastCenter/index.vue"),
  },
  // 会员中心
  {
    path: "/memberIndex",
    name: "memberIndex",
    component: memberIndex,
    meta: {
      title: "会员中心",
    },
    // component: () => import("../views/memberIndex/index.vue"),
  },
  // 帮助中心
  {
    path: "/downloadCenter",
    name: "downloadCenter",
    component: downloadCenter,
    meta: {
      title: "帮助中心",
    },
    // component: () => import("../views/helpCenter/downloadCenter/index.vue"),
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router

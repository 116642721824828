<template>
  <div class="support_wrapper">
    <div
      class="content-title"
      style="--d2c729b8-props_mt: 80px; --d2c729b8-props_mb: 60px"
    >
      支持的直播平台
    </div>
    <div
      class="content row j-sb width1200 m-auto wow bounceInUp"
      style="visibility: visible"
    >
      <span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_one.3de4a724.png"
          alt="img"
          style="height: 70px; width: 169px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_two.378f24aa.png"
          alt="img"
          style="height: 80px; width: 160px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_the.9d7f6dea.png"
          alt="img"
          style="height: 60px; width: 197px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_four.bee1bfdf.png"
          alt="img"
          style="height: 64px; width: 170px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_five.31dd3d46.png"
          alt="img"
          style="height: 63px; width: 175px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_six.40620662.png"
          alt="img"
          style="height: 49px; width: 202px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_seven.97c7cec0.png"
          alt="img"
          style="height: 47px; width: 231px" /></span
      ><span class="inner"
        ><img
          class="cm-center"
          src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/s_eight.3b62e624.png"
          alt="img"
          style="height: 60px; width: 232px"
      /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Support",
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.support_wrapper {
  min-width: 1200px;
  height: 628px;
  background: linear-gradient(180deg, #ffffff 0%, #f3f7fd 100%);
}

.content-title {
  font-size: 48px;
  line-height: 60px;
  padding: 80px 0 32px;
  text-align: center;
  font-weight: 700;
  color: #222831;
}

.content {
  height: 344px;
  .inner {
    width: 288px;
    height: 156px;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
  }
}

.cm-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.j-sb {
  justify-content: space-between;
}
.row {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.m-auto {
  margin: 0 auto;
}
.width1200 {
  width: 1200px;
}
</style>

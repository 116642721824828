<template>
  <div class="container">
    <div class="digital-human-gallery">
      <h2 class="main-title">100+服务型数字人，24小时在线候命</h2>
      <div class="video-grid">
        <div v-for="(video, index) in videos" :key="index" class="video-card" @click="openVideo(video)">
          <!-- <div class="video-wrapper">
          <video
            :src="video.src"
            :poster="video.poster"
            @mouseover="playVideo($event)"
            @mouseout="pauseVideo($event)"
          ></video>
          <div class="play-icon"></div>
        </div> -->
          <div class="video-wrapper">
            <img :src="video.poster" alt="Video thumbnail" />
            <div class="play-icon"></div>
          </div>
        </div>
      </div>

      <!-- 视频播放模态框 -->
      <div v-if="selectedVideo" class="video-modal" @click="closeVideo">
        <div class="modal-content" @click.stop>
          <video-player class="vjs-custom-skin" ref="videoPlayer" :options="playerOptions" @ready="onPlayerReady" />
          <button class="close-button" @click="closeVideo">&times;</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css"
import { videoPlayer } from "vue-video-player"

export default {
  name: "DigitalHumanGallery",
  components: {
    videoPlayer,
  },
  data() {
    return {
      selectedVideo: null,
      playerOptions: {
        autoplay: true,
        controls: true,
        sources: [],
      },
      videos: [
        {
          src: require("@/assets/videos-show/1.mp4"),
          poster: null
        },
        {
          src: require("@/assets/videos-show/2.mp4"),
          poster: null
        },
        {
          src: require("@/assets/videos-show/3.mp4"),
          poster: null
        },
        {
          src: require("@/assets/videos-show/4.mp4"),
          poster: null
        },
        {
          src: require("@/assets/videos-show/5.mp4"),
          poster: null
        },
        {
          src: require("@/assets/videos-show/6.mp4"),
          poster: null
        },
        // {
        //   src: "https://mobvoi-digitalhuman-public.weta365.com/1686662462759374848.mp4",
        //   poster:
        //     "https://mobvoi-digitalhuman-public.weta365.com/1686688979895848960.png",
        // },
        // {
        //   src: "https://mobvoi-digitalhuman-public.weta365.com/1686662467146616832.mp4",
        //   poster:
        //     "https://mobvoi-digitalhuman-public.weta365.com/1686688998199791616.png",
        // },
        // {
        //   src: "https://mobvoi-digitalhuman-public.weta365.com/1686662471512887296.mp4",
        //   poster:
        //     "https://mobvoi-digitalhuman-public.weta365.com/1686689004524802048.png",
        // },
        // {
        //   src: "https://mobvoi-digitalhuman-public.weta365.com/1686662475270983680.mp4",
        //   poster:
        //     "https://mobvoi-digitalhuman-public.weta365.com/1686689005774704640.png",
        // },
        // {
        //   src: "https://mobvoi-digitalhuman-public.weta365.com/1686662479821803520.mp4",
        //   poster:
        //     "https://mobvoi-digitalhuman-public.weta365.com/1686689007200768000.png",
        // },
        // {
        //   src: "https://mobvoi-digitalhuman-public.weta365.com/1686662483290492928.mp4",
        //   poster:
        //     "https://mobvoi-digitalhuman-public.weta365.com/1686689009155313664.png",
        // },
        // ... 添加更多视频，总共应该有20个
      ],
    }
  },
  async mounted() {
    // 为每个视频生成封面
    for (let video of this.videos) {
      try {
        video.poster = await this.getVideoThumbnail(video.src)
      } catch (error) {
        console.error('获取视频封面失败:', error)
      }
    }
  },
  methods: {
    // playVideo(event) {
    //   event.target.play()
    // },
    // pauseVideo(event) {
    //   event.target.pause()
    //   event.target.currentTime = 0
    // },
    openVideo(video) {
      this.selectedVideo = video
      this.playerOptions.sources = [{ type: "video/mp4", src: video.src }]
    },
    closeVideo() {
      this.selectedVideo = null
    },
    onPlayerReady(player) {
      this.player = player
    },
    // 添加获取视频第一帧的方法
    async getVideoThumbnail(videoUrl) {
      return new Promise((resolve) => {
        const video = document.createElement('video')
        video.src = videoUrl
        video.crossOrigin = 'anonymous'
        video.load()

        video.addEventListener('loadeddata', () => {
          video.currentTime = 0
        })

        video.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext('2d')
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          const thumbnailUrl = canvas.toDataURL('image/jpeg')
          resolve(thumbnailUrl)
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  height: 729px;
  background-color: #f3f7fd;

  .main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
}

.digital-human-gallery {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.video-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-wrapper {
  position: relative;
  // padding-top: 56.25%; // 16:9 宽高比
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid white;
    margin-left: 5px;
  }
}

@media (max-width: 1024px) {
  .video-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 1200px;
  aspect-ratio: 16 / 9;
}

.close-button {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
}

.vjs-custom-skin {
  width: 100%;
  height: 100%;
}

// 确保视频播放器填满容器
::v-deep .video-js {
  width: 100%;
  height: 100%;
}

// 响应式调整
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
  }

  .close-button {
    top: -30px;
    right: 0;
  }
}
</style>

<template>
  <MainLayout>
    <Member />
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue"
import Member from "./Member.vue"
export default {
  name: "memberIndex",
  components: {
    MainLayout,
    Member,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <MainLayout>
    <div class="layout">
      <div class="container">
        <div class="step1">
          <div class="inner">
            <div class="title">幻腾 一站式数字人视频制作&直播平台</div>

            <!-- <div class="types">
              <div class="type active">
                <img
                  src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon1.e18805ab.png"
                  class="icon"
                />
                <div class="name">数字人视频</div>
              </div>
              <div class="type">
                <img
                  src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon2.9cd964e0.png"
                  class="icon"
                />
                <div class="name">数字人直播</div>
              </div>
              <div class="type">
                <img
                  src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon3.e253c5cc.png"
                  class="icon"
                />
                <div class="name">交互式数字人</div>
              </div>
              <div class="type">
                <img
                  src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon4.e88ceac7.png"
                  class="icon"
                />
                <div class="name">克隆&amp;定制服务</div>
              </div>
            </div> -->
            <div class="types">
              <div v-for="(type, index) in types" :key="index" :class="['type', { active: activeIndex === index }]"
                @click="onTypeClick(index)">
                <img :src="type.icon" class="icon" />
                <div class="name">{{ type.name }}</div>
              </div>
            </div>

            <div class="inner-banner">
              <div class="banner-contains">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <!-- swiper-slide -->
                    <div class="swiper-slide">
                      <div class="step1-figure-video">
                        <div class="step1-bg bg-video_1" style="
                            background-image: url('https://mobvoi-digitalhuman-public.weta365.com/1767446330592071680.png');
                          "></div>
                        <div class="step1-figure-left">
                          <div class="left-title">
                            无需真人拍摄<br />低成本、高效制作视频
                          </div>
                          <div class="left-video1">
                            <div class="video1-item">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+tJREFUWEftl1vIV0UUxX87s8g0hSjISgWRUIuoIMKCHkqoIPAhISKjSCErsKKih7ykYRFlREHS5cHASiwSHwLJoiCK6AKRKSmVlt0Mu9hFU3N7luzzNf/zn3P5vhR6aN7OmTV71uzZlzXGf2TYYHm4+9nADGAs8C2wxsw+HqydKr4zEXc/A1gGXJHZ9FXgDjP7bKiEWom4+2hgIXArMDzZ6BdgTPK9D3gCuM/Mfh0soVoi7n4UMBu4HzgpDB8AngEeMrPP3X0icHfghNf4EbhXODMTvtPIEnH3i4HHAMVDOd4C5uXiIeJGeK0rh+JGeK1rHT1E3P1E4ElgZrJyK3CXmb3UZM3dRwJXFZhFwPgEuxqYa2Y7m9YPEHH3U4F3gHGx4A/gQeBhM9tTZ8TdTyiu4TTguMAcA1wN3AgcH/++AqaZ2Td1dlIirwGXBvDlcGvtQnc/FjgdUDDnhuJqHnBZTK43s+mNRNx9auHWDQFaCcwyM88tcvdhwCnAyUWGtGYdsDhJ+almtjFn95Ahd78OWBGA883s/SrY3YVVDOkKj26Nvn8AU4Dn4vNmYHnukCWR2woXPxrgMWkdcHfds1JWmz8F/JQhIe9cA3wBrK3MK4jfjH+PFNf5LLDVzHanuJLI7VE1NVclopQsDamILQXeSIyo3Osg2nA/cCHwdzKfElFlfj5wm1MyXYgofh4ArkyMq6TL3SJwQfJf1/t4g0dKIoLsMbNPS2wjEXdXKp4VYGXUPZWyXtr5ISrwu5lry3mkhH1iZnv10UZkBDA5Ma5gnQ9clPxbE/GlupMbTUQ2mdmfQyFSbqQ6o+q5OYK0KYmOKBFlz7nAB8BNLan8P5Gqg6p15IUE0Bcjs5LqN1BZ3b0arKUNZY867Ytqii1Xk1bWBYBSvxx9RLK9poGIquyZ0Z9UxJrGEuDyAEhefFlLRBPu3td9i+75cyV9W/bsmVb3vRO4JP6+V6i3WyoGej0SRHJ6RG5fBxwqOh2HiqCu+vpEo3wP3BAyMjXTTyTI5BSangySga93IKLTS4PoqVGO9dEicoK6t7JWN6jRrB9GYG7JEJoU13BeMqdiJ49+VHOA/l6TA4aKnwMo2FIVr5Kuhrc9JKK0jDpwqeIVV9K9ryj0akgowPu7b5PL410jlTW38q75DRiVrJXxVYVwerrwzu8NNtVb8nqkw90rq/TSU4vP6c63o/Fta7AlotLAO2sVWhciJcbdzwGuBSZEFkg05eJmYAmwA/jOzFLB1LNtF/Gb5dlBxWudMuVrM/ur7bBDJpJ4qPqu0ZT06HYz29VGoJz/10QSQmpuKmZ7zawpWLPcDhuRrievwx0EqGJ4Moho5ZcAAAAASUVORK5CYII=" /><span>易操作</span>
                            </div>
                            <div class="video1-item">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA/dJREFUWEftl1moVXUUxn9fk41UWEEWERUNVpQl4otFA0TRQ49REJnIxYoimowiGkgQNSJoICIKmh7qoSKIBoqi8iGw1CzJ8ppZYlcbMMqm1f6u61z23e5zz99zfPCh9XLP2Xet9f/2+n/rW+uI3cS0m+CgJ5CIOAo4Fti7EHQAm4AvJflzkXUFEhFHAM8DFxRl2tFpHXCVpPdL4luBRMTxwNtZiZI83Xy2AVdKerlXkh2ARISv4CNgegY/CrwG/NErWf7fOc8E7gUOAn7zd0lrJopvA7IAuCODFkm6rRDAOLeIOD+r6jP8YrMk/dst1zggEXEO8C6wB7AMmCnpz36AOCYiHgJuzPj5khb2BBIRBwPLgWOA36vrOFvSF5nwvOrZ7VnqfRJoG79+AR4Dvs4DJwHPJdf8QtMlrWgDM5YsIhxwRTpdJ8ncGLWIeAO4qLAyLwKLa75Tq5d7CtgLWAVMa6vyKJCIuBx4IYNfl3Rp/dCIcEXuAfbrAeZX4HFgbcNvCJibz5ZIuqWZRxGxJ7AacMtaiE6X5L9jFhEnAL66fs3VeBo4GfgLOE7Sd/VkBnKSVTAf3iqpXlZX6/DkTb8gOnFu6Sfzy92S7m8COQP4NB8OSXqixo19gVOSnIMCcUXfySQm9J2SfuokdUVcth+BQ/JuZ0gaiQjzx6Xcf1AEGX+TVTY/3wBY+ldJ8lVtH3oRcS3wSDp9XDlZjA4FpuwiEFcD12euz4FrgH+AnyWNtnq9fZ/xkEpnzwbfoas1qLkjO10yAsypOmhDLelKSdvqQDxjrBeuhu3Z6rqsjIPYZeZCvrD54BYebiRcXbXz1qbEm1AfAqemszvIAtWPXVIFWXs8Lqwv1pKvGon+BlZ4BrUNPUv8UuBIwEPKQ++9nURyYbVIPVBdrzVqa6VP5qBVtW5emtZIMsj2DS0ipiWrD8zx77cxyUpsFrAo+eWZZZJ+1hL4zbj27ZY5Ii4GXs2EW4DZDZK1hc4EHgQ8GL0UefJ+0uK4VpJzjtmEO2tEmFwdgfPqZzCjpWyxs4CHAYugteHm3EOaruusU82HJctzfVGyAs/Lg+q5TgM8rS1+JuD8Lrxa35xjnSQlQOzjVu6sCG/VNjjnsfpasr0Wmtx3AW+2VGyDpI3dqNATiAMjwnfu5OdmInfRK4B/apjIBuEuuC/32+Z530v6YSKmFwFJMJb8D2oa02xFr4EvtRy2UVJdSVvxFANJMJNT+k3ijvx/W3WJedTWHZskrS/p+Z0C0kkYEdaXE4HDgM1dDhqR5E4rsr6A1AB5dfRiZQWtmzVieJf85Cx6je1ENpijKzU9IFvXu42vpPh3r88aqCKlYEv8/gfSrNJ/ErohMgO/CKgAAAAASUVORK5CYII=" /><span>高品质</span>
                            </div>
                            <div class="video1-item">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+NJREFUWEfVl0nMnVMYx39/Q2KKWai5hsQsTbBAWLAxBBFFtVggLRZIxBAxxMJag0iLVZGiJCJsqFiUEHOIRkmpsdHWUE2V4HH+X54j73e/+9779rqLepIv+e65Z/idZ/if54otxLSFcPD/AYmIvYBDgT2Bn4AvJH03bk/29UhEbANclX8nwBTPfVTgFhW4ByX9Ng6oKSARMQN4Cji8wwHfA3Mkvdph7sApk0Ai4hzgaWCHXLWyAD0BvA6sBXYDTgRmAcfmnD+B2ZK8bmT7FyQivPEbwE6AN7+tHDxfkv+fZBHhdVcCDyX0S5J8iZFtAiQ3fh84HvgLOF/Si8N2jYhjMo8WSXpv2PxB31eQizMvPPduSfc2F0XEtsBJ6akVklw9Y7UK8gJwLvADMF3SxnpKRFwBPADsnGP22GvAM8BzktaMg0hZquuB7YGFkuY2IJyUTw44aGxQBtkP+CYPu0bSo428+QrYH3CZXg9sAJyUFwFe17T/BGWQo8vGH+eOMyUtSZCDgC9z/EZJ8xueckhPBmaOC8ogewOr85DrJD2cINOAKuU3Sbq/J4Et/fsCVuEjgdOBs3Os11PPArdIWtUWZoNsBawDdrV4SZrTuLlD5hAslXRmY3wX4LCWTY9KqLN6oL4GZkjyWVOsVs1i4BLg16yaickRYS/ckNoyrVZIREwHdu9QLdaZC4Hzcu59ku4YBOLbvpwTFkialyCnAMtyfJ6kBTl+cCnpPTqA1Cm+qD34JmBPrZf0d3N9U+JfAc7IL2dJWpyKa5dOCk9E+M05pCOIc80vtUO/FLgVMMQvzs2qWU0QH/YOsE+J5fOSLmgLT+bVccDWA2B88GzAqr1jzrs5xbAuM9BySZt6X1/H9Frgsfp2RERbeA4sj54rp9fsrcuztC2S1R4vXplUefnFtyUVVg9tFQeEx7c8oofCFef82q4x/gHg3Hq7xXurJK0dCjKkeiyG9VB70l1dtXdLbj2S4W6LoJX6MyduV5C28FgM/QS4itzVOWcsjneVkh3UFvye89ZJClN2BfG8ftXj9sBJe0++3k6+SwF3dv1sUwL8WAHqpE4gQ8JzWpalpd5adHsfAj+IvsgUgFFAmuGZK2lhAjoPrs4NLwNW9AH5XJJ1o9U2xyPN8FghTy1lfkDRiU+yl3GD7eeg1zZI+nQQROccqZsUIXMLeWd+Xp4/uqqWuGI+HMUbo4C4w3+r/OTwC9s09yqW8V7bKMnAQ61zaBpeMYyl2v3Hz9k6LMkGqylkXrKya6O92SBtV+vzEFqs3PFP6MQwGxuID4oId/p+7P4oPe4aSS7bTjZWkE4ntkz6Bw/EejJDfV/CAAAAAElFTkSuQmCC" /><span>定制化</span>
                            </div>
                            <div class="video1-item">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAAAXNSR0IArs4c6QAABDhJREFUWEfNl2voZWMUxn8Pxl3GbTIi9y9I+GBo8kkjH5REig+SEsUkt1wGGTEjIbcioSS3XHIrdzW5+0AuExnGDNNEblPjMm6zvM+09r999tnnnH1OI//15X/+e693vc/7rOdda20xTUzTBAcbBUhEzABOBpZIWjXJ4TYWkMuB64CPJR38fwKZD9yWAOZKemtcMGMzEhH7AXsAuwJrgC+BX4CVwGbA3ZLO+k+ARMS+gE99PLBXyybry/sfgV0S3GxJ68YBM5SRiNgWWAScXTRgQXa1xZKsm842EEhE7A08AxyU0Xzq14CngY8KuG+BmYBTdTpwNLBJ+i6XZBY7WyuQiLAG3ksdONirwAWSDGDKIuIQ4H6gflPWAvMkvdsZBfTXkYjYugR6E/AmtuuBBZLMSI9FxPuFoUPz4deFkWsKqCeBP4HfMp0zJP06ClQfIxHhYFfmwpslXdgWJCK2SP2cCDwHPJgAKncf6IkS6x9gjiQDHWg9QCLCV/ILYBvgHcA1oYeJiPCa2Zm2YWK3fl5O1u8EzpEUg5A0gVwKLE7noyS90dCExbgPsP0oqvP97cCRgHUzF1jalmL7NoGYhTmDSnXepB07grCbN781/a8AHpL0Vdv6KSARsRVgUfnZIkkLGmzsNKCYjUq9y71r0L2AU7RCkotfj9WBmHKXa9tppQ48UHkWATslBxaAm4/BRuX6eB7gRd++FHRfiupAjgDeztXHSvLCDRYRO6Q2JsDBHYBjuwadkQFc8H6uB+vKiKvsONqo73FfFjzr79x88VNTK3Ug7py/ZwddKOnqGiNOy5aT0FHE/3w2w2eL/hZmjHWSlrYykilwDXGPeEXSvBoQV8+qj4yDx7EezQU3Ao/k7/WSPhgG5IZycy62LIADJH2WACcF4lS4IdqOy0bp3yOB7Jk3Z9PSWW+RdH4CcQd2Sbd5HrHfkhHU7Fz61VOZUp/+zJr/H5I+GchIbvoYcBLwsKRT85kpdsk+BTgvdeQC9cIAMD6Iq+rh+d63pd6510iqSsUGl7am52Z1AvCSpO8TiFm4J2cOP/rLtQZY1gLEojdIp8Lmhjgl/Hy2UtIPQxlpBo4Ifyb4dLPy3epyuy4BPm0BsXvJ/1VloDos3/lmOCUeCypzN/a0779TNnJ4jgh338pvRVZH364qkBnwxsckC/7f9mEZqi8qqewpXMAqSd81D9EFyN+Ac143P3Mwl3wXuvp7A/dwdFOmsL7OXXhZ2zjQBYiH58ta0tB8ZN14srsrZ5rme09sBuFD9FkXIPsDn+dKC/b1TIVvkje3oJeX1LjLerM2c3rcdfvGzcp5JBA7RoQ38YDzjWuIqY2I7crH1G6APzkGmT+8Vpdx0ykZal2B+MvNlNtmVdc6QXrWMCjrxUI19b4la8tMY8Y6WVcg3sDl313z2k6Rx3TqBGTMmBO5Txsg/wK18lIziyh2XwAAAABJRU5ErkJggg==" /><span>批量生成</span>
                            </div>
                          </div>
                          <div class="left-btn">免费试用</div>
                        </div>
                        <div class="step1-figure-right">
                          <img class="img-video-1"
                            src="https://mobvoi-digitalhuman-public.weta365.com/1767446370341490688.png" />
                        </div>
                      </div>
                    </div>

                    <!--  -->
                    <div class="swiper-slide">
                      <div class="step1-figure-video">
                        <div class="step1-bg bg-video_4" style="
                            background-image: url('https://mobvoi-digitalhuman-public.weta365.com/1767446336103387136.png');
                          "></div>
                        <div class="step1-figure-left">
                          <div class="left-title">
                            PPT一键生成讲解视频<br />演讲、培训、录课更方便
                          </div>
                          <div class="left-desc">
                            适用于演讲视频、培训视频、课程视频制作，让课件更有趣，让演讲更生动
                          </div>
                          <div class="left-btn">免费试用</div>
                        </div>
                        <div class="step1-figure-right">
                          <div class="right-video3">
                            <img
                              src="https://mobvoi-digitalhuman-public.weta365.com/1767446364331053056.png" /><!--v-if-->
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--  -->
                    <div class="swiper-slide banner_item" data-swiper-slide-index="7" style="width: 1200px;">
                      <div class="step1-figure-video">
                        <div class="step1-bg bg-mutual_1"
                          style="background-image: url(&quot;https://mobvoi-digitalhuman-public.weta365.com/1767446345947418624.png&quot;);">
                        </div>
                        <div class="step1-figure-left">
                          <div class="left-title">为企业打造交互数字员工<br>提升服务效率和服务质量</div>
                          <div class="left-desc">搭载大模型，自定义数字角色，可打造数字客服、数字接待、数字讲解员、数字前台等数字员工</div>
                          <div class="left-btn">免费试用</div>
                        </div>
                        <div class="step1-figure-right">
                          <div class="right-video3"><img
                              src="https://mobvoi-digitalhuman-public.weta365.com/1767745505959481344.png"
                              data-cubox-image-id="353f29d8e384be2a9c988c39a1a97a46" data-cubox-image-index="15"
                              data-cubox-image-load="true"><!--v-if--></div>
                        </div>
                      </div><!---->
                    </div>

                    <div class="swiper-slide banner_item" data-swiper-slide-index="4" style="width: 1200px;">
                      <div class="step1-figure-video">
                        <div class="step1-bg bg-live_1"
                          style="background-image: url(&quot;https://mobvoi-digitalhuman-public.weta365.com/1767446338141818880.png&quot;);">
                        </div>
                        <div class="step1-figure-left">
                          <div class="left-title">无需专业团队，低成本搭<br>建直播间，助力电商直播</div>
                          <div class="left-desc">无需专业团队，低成本搭建直播间，提升GMV和线索转化</div>
                          <div class="left-btn">免费试用</div>
                        </div>
                        <div class="step1-figure-right">
                          <div class="right-video3"><img
                              src="https://mobvoi-digitalhuman-public.weta365.com/1767446340461268992.png"
                              data-cubox-image-id="8726f5b680dbed21269e9909e9b77422" data-cubox-image-index="12"
                              data-cubox-image-load="true"><!--v-if--></div>
                        </div>
                      </div><!---->
                    </div>

                  </div>
                </div>

                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 合作 -->
        <ClientSection />

        <!-- 视频展示 -->
        <VideoSection />

        <!-- 克隆 -->
        <Step8 />

      </div>
    </div>
  </MainLayout>
</template>

<script>
// 导入 Swiper
import Swiper from "swiper"

// Import Swiper styles
import "swiper/css/swiper.css"

import MainLayout from "@/layouts/MainLayout.vue"
import ClientSection from "./ClientSection.vue"
import VideoSection from './VideoSection.vue'
import Step8 from './Step8.vue'

export default {
  name: "HomeView",
  components: {
    MainLayout,
    ClientSection,
    VideoSection,
    Step8
  },
  data() {
    return {
      types: [
        {
          name: "数字人视频",
          icon: "https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon1.e18805ab.png",
        },
        {
          name: "数字人直播",
          icon: "https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon2.9cd964e0.png",
        },
        {
          name: "交互式数字人",
          icon: "https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon3.e253c5cc.png",
        },
        {
          name: "克隆&定制服务",
          icon: "https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon4.e88ceac7.png",
        },
      ],
      activeIndex: 0,
      swiper: null, // 用于存储 Swiper 实例
    }
  },
  mounted() {
    // 初始化 Swiper
    this.swiper = new Swiper(".swiper-container", {
      // 配置选项
      loop: true, // 循环模式
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 3000, // 自动播放间隔时间
      },
      on: {
        // 监听 slide 切换事件
        slideChange: this.onSlideChange,
      },
    })
  },
  methods: {
    onTypeClick(index) {
      this.activeIndex = index
      // 切换到对应的 Swiper
      this.swiper.slideTo(index)

    },
    // Swiper 滑动时更新菜单的选中状态
    // onSlideChange() {
    //   // Swiper 的 activeIndex 会随着轮播图切换而更新
    //   this.activeIndex = this.swiper.realIndex; // 注意使用 realIndex 来获取真实索引
    // }
  },
}
</script>

<style lang="scss" scoped>
.step1 {
  width: 100%;
  height: 997px;
  background-image: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/bg.cdca446e.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;

  .title {
    font-size: 40px;
    font-weight: 800;
    color: #0f313e;
    line-height: 48px;
    margin-bottom: 60px;
  }

  .types {
    display: flex;
    margin-bottom: 30px;

    .type {
      width: 140px;
      height: 140px;
      background: transparent;
      border-radius: 22px;
      border: 4px solid transparent;
      margin-right: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .active {
      background: linear-gradient(180deg,
          rgba(134, 189, 255, 0.45) 0%,
          rgba(167, 210, 255, 0.23) 100%);
      border-radius: 22px;
      border: 4px solid white;
    }
  }

  .inner-banner {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    .banner-contains {
      width: 1200px;
      position: relative;
      border-radius: 32px;
      overflow: hidden;
    }

    .step1-figure-video {
      width: 1200px;
      height: 484px;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: row;
      border-radius: 32px;

      .step1-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: linear-gradient(133deg, #607dfe 0%, #666cee 100%);
      }

      .step1-figure-left {
        width: 410px;
        height: 484px;
        padding: 50px 30px 0 50px;
        color: #fff;
        // font-family: HarmonyOS_Sans_SC_Bold;
        position: relative;

        .left-title {
          font-size: 30px;
        }

        .left-video1 {
          margin-top: 24px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .video1-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .left-btn {
          position: absolute;
          left: 50px;
          bottom: 120px;
          width: 170px;
          height: 64px;
          border-radius: 32px;
          background: linear-gradient(134deg, #ffffff 0%, #a9ceff 100%);
          font-size: 20px;
          color: #000;
          text-align: center;
          line-height: 64px;
        }
      }

      .step1-figure-right {
        width: 790px;
        height: 484px;
        position: relative;

        .right-video3 {
          position: absolute;
          right: 0;
          width: 740px;
          height: 483px;
        }
      }
    }
  }
}
</style>

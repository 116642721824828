<template>
  <MainLayout>
    <div class="layout">
      <div class="container">
        <div class="customized-one">
          <div class="customized-box">
            <div class="box-left">
              <h1>24小时直播</h1>
              <p>数字人直播，节约90%成本，让直播更便捷</p>
              <div class="btn">立即制作</div>
            </div>
            <img class="box-right" src="~@/assets/makehome_bg.png" />
          </div>
        </div>
      </div>

      <!-- moreNeed -->
      <MoreNeed />

      <!-- 价格 -->
      <PriceReview />

      <!-- 平台 -->
      <Support/>


    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue"
import MoreNeed from './MoreNeed.vue'
import PriceReview from './PriceReview.vue'
import Support from './Support.vue'
export default {
  name: "broadcastCenter",
  components: {
    MainLayout,
    MoreNeed,
    PriceReview,
    Support
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  position: relative;
}

.container {
  .customized-one {
    background: #040d1e;
    overflow: hidden;
    position: relative;

    .customized-box {
      width: 1200px;
      margin: 0 auto;
      height: 650px;
      color: #fff;
      position: relative;
      // border: 1px solid #fff;
      z-index: 999;

      .box-left {
        width: 800px;
        position: relative;
        z-index: 1;
        text-align: left;

        h1 {
          font-size: 72px;
          padding-top: 206px;
          line-height: 80px;
          font-weight: 600;
        }

        p {
          line-height: 38px;
          margin-top: 32px;
          font-size: 22px;
        }

        .btn {
          display: inline-block;
          padding: 0 75px;
          margin-top: 60px;
          height: 56px;
          line-height: 56px;
          background: linear-gradient(134deg, #00a4ff 0%, #006eff 100%);
          box-shadow: 0 10px 28px #5b83ff4d;
          border-radius: 28px;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .box-right {
        height: 650px;
        object-fit: cover;
        position: absolute;
        top: 0;
        // right: -360px;
        right: -200px;
      }
    }
  }
}
</style>

<template>
  <div class="container">
    <h1 class="main-title">轻松制作数字人视频</h1>
    <div class="content">
      <div class="steps">
        <div v-for="(step, index) in steps" :key="index" class="step-item" :class="{ active: currentStep === index }"
          @click="setCurrentStep(index)">
          <div class="step-left">
            <!-- <h2>{{ step.number }}</h2> -->
          </div>
          <div class="step-center">
            <p class="step-center-title">{{ step.title }}</p>
            <p class="step-center-desc">{{ step.desc }}</p>
          </div>
          <div class="step-right"></div>
        </div>
      </div>
      <div class="preview">
        <transition name="fade" mode="out-in">
          <img v-if="currentContent.type === 'image'" :key="currentStep" :src="currentContent.src" alt="Preview" />
          <video v-else :key="currentStep" controls>
            <source :src="currentContent.src" type="video/mp4" />
          </video>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalHumanSteps",
  data() {
    return {
      currentStep: 0,
      steps: [
        {
          number: "01",
          title: "声音克隆",
          desc: "一次录音，创造无限可能。数字人AI语音合成，让你的声音永不沉默。",
          content: { type: "video", src: require("@/assets/videos/step1.mp4") },
        },

        {
          number: "02",
          title: "数字人克隆",
          desc: "一键生成数字人，让你的形象永不消失。数字人AI形象克隆，让你的形象永不消失。",
          content: { type: "video", src: require("@/assets/videos/step2.mp4") },
        },

        {
          number: "03",
          title: "AI直播",
          desc: "AI数字人直播，解放你的双手，让你的直播持续在线、永不间断。",
          content: { type: "video", src: require("@/assets/videos/step3.mp4") },
        },
        {
          number: "04",
          title: "文案助手",
          desc: "文案助手，让创意更简单，一句直击心底的表达，为你轻松实现！",
          content: { type: "video", src: require("@/assets/videos/step4.mp4") },
        },

      /*  */
        // {
        //   number: "01",
        //   title: "点击创建项目",
        //   desc: "选择现有模板或者点击“创建项目”，开启你的内容创作。",
        //   content: { type: "image", src: "/images/step1.jpg" },
        // },
        // {
        //   number: "02",
        //   title: "选择数字人并输入文本",
        //   desc: "选择合适的数字人形象并输入语音文本，可进行自定义调整。",
        //   content: { type: "video", src: require("@/assets/videos/step2.mp4") },
        // },
        // {
        //   number: "03",
        //   title: "选择背景",
        //   desc: "点击编辑背景进行背景文本及背景动画编辑，可自行创作背景内容。",
        //   content: { type: "image", src: "/images/step3.jpg" },
        // },
        // {
        //   number: "04",
        //   title: "导出视频",
        //   desc: "只需点击“导出视频”按钮，即可几分钟生成数字人讲解视频。",
        //   content: { type: "video", src: "/videos/step4.mp4" },
        // },
      ],
    }
  },
  computed: {
    currentContent() {
      return this.steps[this.currentStep].content
    },
  },
  methods: {
    setCurrentStep(index) {
      this.currentStep = index
    },
    startAutoPlay() {
      this.intervalId = setInterval(() => {
        this.currentStep = (this.currentStep + 1) % this.steps.length
      }, 20000)
    },
  },
  mounted() {
    this.startAutoPlay()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  height: 729px;
}

.main-title {
  font-size: 48px;
  line-height: 60px;
  padding: 80px 0 60px;
  text-align: center;
  font-weight: 700;
  color: #222831;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.steps {
  width: 30%;
}

.step-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
  border-radius: 8px;
  display: flex;
}

.step-item:hover,
.step-item.active {
  background-color: #e0e0e0;
}

.step-left {
  width: 50px;
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.step-center {
  p {
    text-align: left;
  }

  .step-center-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .step-center-desc {
    font-size: 14px;
    color: #666;
  }
}

.preview {
  width: 65%;
}

.preview img,
.preview video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

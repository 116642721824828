<template>
    <div class="main-layout">
      <MainNavbar :isScrolled="isScrolled" />
      <main class="main-content">
        <slot></slot>

        <Tools />
      </main>
      <footer class="main-footer">
        <!-- 添加页脚内容 -->
      </footer>
    </div>
  </template>
  
  <script>
  import MainNavbar from './MainNavbar.vue'
  import Tools from '@/components/Tools.vue'
  
  export default {
    name: 'MainLayout',
    components: {
      MainNavbar,
      Tools
    },
    data() {
      return {
        isScrolled: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll() {
        this.isScrolled = window.scrollY > 50
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    flex: 1;
    // padding-top: 72px; // 导航栏的高度
  }
  
  .main-footer {
    // 添加页脚样式
  }
  </style>
<template>
  <MainLayout>
    <div class="layout">
      <!-- nav -->
      <!-- <div class="main-header-root" :class="{ scrolled: isScrolled }">
        <div class="main-header">
          <div class="header-left">
            <div class="header-logo">
              <img
                alt="Vue logo"
                src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo-light.d36a8e12.png"
              />
            </div>
            <div class="header-link">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                :background-color="isScrolled ? '#ffffff' : '#040d1e'"
                :text-color="isScrolled ? '#333333' : '#ffffff'"
                active-text-color="#006eff"
              >
                <el-menu-item index="1">首页</el-menu-item>
                <el-menu-item index="2">视频制作</el-menu-item>
                <el-menu-item index="3">直播中心</el-menu-item>
                <el-menu-item index="4">客户案例</el-menu-item>
                <el-menu-item index="5">会员中心</el-menu-item>
              </el-menu>
            </div>
          </div>
          <div class="header-right"></div>
        </div>
      </div> -->
      <div class="container">
        <div class="customized-one">
          <div class="customized-box">
            <div class="box-left">
              <h1>数字人视频制作</h1>
              <p>一键将文本合成视频，轻松批量制作</p>
              <div class="btn">免费试用</div>
            </div>
            <img class="box-right" src="~@/assets/main_bg.png" />
          </div>
        </div>
      </div>

      <div class="section">
        <!-- 轻松四步 -->
        <digital-human-steps></digital-human-steps>

        <!-- 颠覆传统 -->
        <video-production-comparison></video-production-comparison>

        <!-- 8大场景 -->
        <digital-human-applications></digital-human-applications>

        <!-- 100型 -->
        <digital-human-gallery></digital-human-gallery>
      </div>
    </div>
  </MainLayout>
</template>

<script>
// @ is an alias to /src
import MainLayout from "@/layouts/MainLayout.vue"
import DigitalHumanSteps from "./DigitalHumanSteps.vue"
import DigitalHumanApplications from "./DigitalHumanApplications.vue"
import VideoProductionComparison from "./VideoProductionComparison.vue"
import DigitalHumanGallery from "./DigitalHumanGallery.vue"

export default {
  name: "makehome",
  components: {
    MainLayout,
    DigitalHumanSteps,
    DigitalHumanApplications,
    VideoProductionComparison,
    DigitalHumanGallery,
  },
  data() {
    return {
      activeIndex: "1",
      isScrolled: false,
      currentStep: 0,
      steps: [
        {
          number: "01",
          title: "点击创建项目",
          content: { type: "image", src: "/images/step1.jpg" },
        },
        {
          number: "02",
          title: "选择数字人并输入文本",
          content: { type: "video", src: "/videos/step2.mp4" },
        },
        {
          number: "03",
          title: "选择背景",
          content: { type: "image", src: "/images/step3.jpg" },
        },
        {
          number: "04",
          title: "导出视频",
          content: { type: "video", src: "/videos/step4.mp4" },
        },
      ],
    }
  },
  computed: {
    currentContent() {
      return this.steps[this.currentStep].content
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },

    setCurrentStep(index) {
      this.currentStep = index
    },
    startAutoPlay() {
      this.intervalId = setInterval(() => {
        this.currentStep = (this.currentStep + 1) % this.steps.length
      }, 5000)
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50 // 当滚动超过50px时更新状态
    },
  },
}
</script>
<style scoped lang="scss">
/* 样式重置 */
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.layout {
  width: 100%;
  height: 100%;
  position: relative;
}

.main-header-root {
  width: 100%;
  height: 72px;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  // background-color: rgba(0,0,0,0);
  background-color: #040d1e;
  z-index: 9999;
  transition: all 0.3s ease;

  &.scrolled {
    // background-color: rgba(4, 13, 30, 0.9); // 半透明背景
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 60px; // 稍微减小高度

    .main-header {
      height: 50px;
    }

    .header-logo {
      width: 90px; // 稍微缩小 logo
      height: 30px;
    }

    .el-menu--horizontal > .el-menu-item {
      height: 60px;
      line-height: 60px;
    }

    // .el-menu{
    //   background-color: #fff;
    // }
  }

  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    // background-color: #fff;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .header-left {
    display: flex;
    align-items: center;

    .header-logo {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 80px;
      width: 104px;
      height: 34px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-link {
    margin-left: 20px;
    font-size: 20px;
  }
}

.container {
  .customized-one {
    background: #040d1e;
    overflow: hidden;
    position: relative;

    .customized-box {
      width: 1200px;
      margin: 0 auto;
      height: 650px;
      color: #fff;
      position: relative;
      // border: 1px solid #fff;
      z-index: 999;

      .box-left {
        width: 800px;
        position: relative;
        z-index: 1;
        text-align: left;

        h1 {
          font-size: 72px;
          padding-top: 206px;
          line-height: 80px;
          font-weight: 600;
        }

        p {
          line-height: 38px;
          margin-top: 32px;
          font-size: 22px;
        }

        .btn {
          display: inline-block;
          padding: 0 75px;
          margin-top: 60px;
          height: 56px;
          line-height: 56px;
          background: linear-gradient(134deg, #00a4ff 0%, #006eff 100%);
          box-shadow: 0 10px 28px #5b83ff4d;
          border-radius: 28px;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .box-right {
        height: 650px;
        object-fit: cover;
        position: absolute;
        top: 0;
        // right: -360px;
        right: -200px;
      }
    }
  }
}
</style>

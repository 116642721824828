<template>
  <div class="member-new">
    <div class="main">
      <div class="head_vip">
        <div class="el-affix" style="">
          <div class="" style="">
            <div class="menu_container">
              <!-- <div class="menu">
                <div class="menu_item menu_item_active">视频制作会员</div>
                <div class="menu_item">直播会员</div>
              </div> -->
              <div class="menu">
                <div
                  v-for="(item, index) in menuItems"
                  :key="index"
                  class="menu_item"
                  :class="{ menu_item_active: activeIndex === index }"
                  @click="scrollToSection(index)"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="video_vip" ref="videoVip">
        <div class="head_title">
          <div class="limit">
            <span>短视频制作会员</span
            ><!--v-if-->
          </div>
        </div>
        <div class="vip_list">
          <div
            class="vip_item videoMemberInner"
            :class="{
              ['vip_item_active_' + hoveredItem]: hoveredItem !== null,
            }"
            @mouseenter="hoveredItem = 0"
            @mouseleave="hoveredItem = null"
          >
            <!--v-if-->
            <div class="item_head">免费版</div>
            <div class="item_price">
              <!--v-if-->
              <div class="free"><span>￥</span> 免费</div>
            </div>
            <div class="item_btn videoMemberInner_itemBtn">免费使用</div>
            <div class="item_list">
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>合成时长：</span
                ><span>30S试用时长</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>页数限制</span>
              </div>
              <!--v-if--><!-- <div class="item_text" v-if="index != 0">
                <img
                  class="icon"
                  src="@/assets/images/memberLive/auth.png"
                  alt=""
                />
                <img
                  v-if="index == 1"
                  class="icon_hover"
                  src="@/assets/images/memberLive/auth_blue.png"
                  alt=""
                />
                <img
                  v-if="index == 2"
                  class="icon_hover"
                  src="@/assets/images/memberLive/auth_blue2.png"
                  alt=""
                />
                <span>{{ item.item9 }}</span>
              </div> -->
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>数字人部分免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>声音免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>模板部分免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>100+张背景、插图免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>导入ppt制作免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>储存空间：</span><span>1G</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><!--v-if--><span>视频分辨率： </span
                ><span> 1080P</span>
              </div>
            </div>
          </div>
          <div class="vip_item videoMemberInner">
            <!--v-if-->
            <div class="item_head">月卡</div>
            <div class="item_price">
              <!--v-if-->
              <div class="nofree">
                <div>
                  <span class="type">￥</span><span class="money">298</span
                  ><span class="unit">/月</span>
                </div>
                <!--v-if-->
              </div>
            </div>
            <div class="item_btn videoMemberInner_itemBtn">立即购买</div>
            <div class="item_list">
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>合成时长：</span><span>40分钟</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>页数不限制</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>支持视频轨道编辑器</span>
              </div>
              <!-- <div class="item_text" v-if="index != 0">
                <img
                  class="icon"
                  src="@/assets/images/memberLive/auth.png"
                  alt=""
                />
                <img
                  v-if="index == 1"
                  class="icon_hover"
                  src="@/assets/images/memberLive/auth_blue.png"
                  alt=""
                />
                <img
                  v-if="index == 2"
                  class="icon_hover"
                  src="@/assets/images/memberLive/auth_blue2.png"
                  alt=""
                />
                <span>{{ item.item9 }}</span>
              </div> -->
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>60+真人、3D数字人免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>500+声音免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>30+模板免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>100+张背景、插图免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>导入ppt制作免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>储存空间：</span><span>30G</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAi5JREFUSEu1lj9oE1Ecxz+/u+Ymo0KGhlKhIEgFLW4WdaogRCFHi9LFRVAQrIJoB0EQQR2CUBB1sVBBUXBoSaAXcNBJwUG7SSNYVDTUTUkGUZIn766XO3NJmgTvprv3+73v9/e+vz/vhE7PlDNMTdkoOYawExjacC+j+IioZUzJs3j0azsYaWnIPh9CatehfgqF2TEIoQbGAsq8RuFIudk3SpAtZpH6YxTJjsBRpArKOEkhUwib/iWwixegPofC6AncdxbqYFwkn7kTLPlvXuRLfYOHSZQx6Z/EO4Gr+Z/VnmVpn9kKKjGqc+IR2MUHqPrpvmRpS2LMk8+cEbxS/LRptbQBGtxmMX9+L6mkxeStt3z/+dvz1NVlyohgL59Dcbef6DX4y5vj7N6xxd0+nXvHs1frAZQwI2QdB1QmTJAwhVQywfqPjWhasKe3W7y4EYC//1Jh/+xrqr9qIW8pCrZTQqldYYyVuUOMjSTJLa5x5VEpAq/BdeSjw17kGnzi6ptAnkZFyQdNUEEpzxOwBoTywmFSWy33+/bSGrMPVxskXYO7eZBqhECvHz+Q5unlfQyYXr/5JD2BhwgiEmnbiYNpnlwKSO47n5kYS20uS1hQ0RK1SLLv00zir7fVPJItN8mdy7RZru7B3V6Y6arRpsYHuXd2D6VvVaZzK9FqadVEjUbTxi5GhZ4pqpduFH9U6E2xDzuXJM5x7R891gvHJ4n1ymyQxHnphyvlP/y2/AUJcxFujt21wAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><span>视频分辨率： </span><span> 1080P</span>
              </div>
            </div>
          </div>
          <div class="vip_item videoMemberInner vip_item_active_2">
            <!--v-if-->
            <div class="item_head">年卡</div>
            <div class="item_price">
              <!--v-if-->
              <div class="nofree">
                <div>
                  <span class="type">￥</span><span class="money">2499</span
                  ><span class="unit">/年</span>
                </div>
                <!--v-if-->
              </div>
            </div>
            <div class="item_btn videoMemberInner_itemBtn">立即购买</div>
            <div class="item_list">
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>合成时长：</span><span>360分钟</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>页数不限制</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>支持视频轨道编辑器</span>
              </div>
              <!-- <div class="item_text" v-if="index != 0">
                <img
                  class="icon"
                  src="@/assets/images/memberLive/auth.png"
                  alt=""
                />
                <img
                  v-if="index == 1"
                  class="icon_hover"
                  src="@/assets/images/memberLive/auth_blue.png"
                  alt=""
                />
                <img
                  v-if="index == 2"
                  class="icon_hover"
                  src="@/assets/images/memberLive/auth_blue2.png"
                  alt=""
                />
                <span>{{ item.item9 }}</span>
              </div> -->
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>60+真人、3D数字人免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>500+声音免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>30+模板免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>100+张背景、插图免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>导入ppt制作免费</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>储存空间：</span><span>30G</span>
              </div>
              <div class="item_text">
                <img
                  class="icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                  alt=""
                /><!--v-if--><img
                  class="icon_hover"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAixJREFUSEu1lj9oE1Ecxz/vLrVpbR08sFo6CILGQUE6dHGxi4hgSVE7tCBC4mIQhHboUMTFQVKK0kprA4KKgvgHBZV0MLMguKktCB0ktI6mtnHIPXl3ucs1d4nX4N34fr/f9/Pe9/3eeydo8nWQ6jNhCMRZ4BDQW00vAt9BvtXg9Ra5H41kRFCgk0yvSfmmhMuA3mwSQEXAA434jU1mFXjb5wN0kj5XQT4Guv8hXB8u6YixTRbfeAPbAB1cuWYiZ0BqOxSvpgtTQ1zf4v5dp94F2DPnVevirqSpQ9JZiQVQnlcof2vBlkYLLenEE2pPLECc1KKEVGu2BFcJyJXJpUW1FVdDdEug0r6ebuZzl9hrdHExOcfP9ZKTV9HgoGgndRWYbWX2SnypMEHi6AGrfHRkgRfPPnmlMqKd9DuQZ7yjsTYdw9jN+tqvhtye/XvIfxh3xb9+KXJy4Ba/N/54asR7BVgGedir9PHzFMeO9zF9O8/U5EsfRIkvFcY5krBnrsRPD2a99jhtu6IAJZBdjkrbLp3VYhbDsIdmsnkmJ567kPDiqkRs+ABqOHm+n0dP08Ri9i3hQHYmXgP4LFKh4Qv9PHxSg8zfK3BqMBHCFq+jwrLIt8lOSj3EGW/sef12WZvcvE3r7QovbsEyoQ7a0PAJ7syNsrK8xtjIQkC3BHazfdBUKNRVoTJl+OPoXhWqJPLLzoZEeF07C4/0wXEgkT6ZNUiEj763T/7Hb8tfUjvzY0FzLC0AAAAASUVORK5CYII="
                  alt=""
                /><span>视频分辨率： </span><span> 1080P</span>
              </div>
            </div>
          </div>
          <!-- 充电包 -->
          <!-- <div
            width282=""
            pt40=""
            ph16=""
            class="row chargingPack vip_item videoMemberInner flex-column"
            style="color: rgb(0, 0, 0)"
          >
            <div fwei600="" lineh36="" class="chargingTitle fs-28 m-auto">
              充电包
            </div>
            <div
              fwei600=""
              margintop30=""
              ph20=""
              lineh26=""
              mb36=""
              class="text-center fs-16"
            >
              提供视频合成、直播合成、 字符数充值包
            </div>
            <div ph10="" class="shortVideo row flex-column">
              <div lineh26="" class="disCount text-center">低至5折</div>
              <div class="shortVideo_title row">
                <span class="shortVideo_title_title">视频</span
                ><span ml4="" class="shortVideo_title_desc">时长包</span>
              </div>
              <div class="shortVideo_inner row j-sb a-center shortVideo_inner0">
                <span>购买短视频时长</span
                ><img
                  class="arrow"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAAAXNSR0IArs4c6QAAASZJREFUWEfN178uBUEUB+DvhoqC6CR6hUbiObQi0XgIJRq8iUjEK9wHkGgVCoXQaXQ6CZNcN1yJuzN7ZrJT7t9vf2f3zM7IwMZoYB41QJt4xVvJw0aDrrGHdxziJhcVCUrJPPwAfOAACdl5RILW8IKlPqhIUHKkcl1hoRQVDUqOfVyWomqAeqFqgYpRNUFFqNqgbFQLUBaqFagz6ht0hGOsdG6pcQf+6ugJtIFnqky0XdlT1FBACX6G0yGULGEuJq9M0zLtYIzVmTpOMWl7q6+sE6YVqDOmBSgLUxuUjakJKsLUAhVjaoB6YaJB6ef+Eev/9Zl5c0lkH9rCfR9MdEKLuMP2BHWOk3mJzO6PTChdexm7X6vWJ9zmYqITKrn/n3OiE+qN+gTorkUhzQa70gAAAABJRU5ErkJggg=="
                  alt="icon"
                />
              </div>
            </div>
            <div ph10="" class="shortVideo row flex-column">
              <div lineh26="" class="disCount text-center">低至5折</div>
              <div class="shortVideo_title row">
                <span class="shortVideo_title_title">直播</span
                ><span ml4="" class="shortVideo_title_desc">时长包</span>
              </div>
              <div class="shortVideo_inner row j-sb a-center shortVideo_inner1">
                <span>购买直播时长</span
                ><img
                  class="arrow"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAAAXNSR0IArs4c6QAAASZJREFUWEfN178uBUEUB+DvhoqC6CR6hUbiObQi0XgIJRq8iUjEK9wHkGgVCoXQaXQ6CZNcN1yJuzN7ZrJT7t9vf2f3zM7IwMZoYB41QJt4xVvJw0aDrrGHdxziJhcVCUrJPPwAfOAACdl5RILW8IKlPqhIUHKkcl1hoRQVDUqOfVyWomqAeqFqgYpRNUFFqNqgbFQLUBaqFagz6ht0hGOsdG6pcQf+6ugJtIFnqky0XdlT1FBACX6G0yGULGEuJq9M0zLtYIzVmTpOMWl7q6+sE6YVqDOmBSgLUxuUjakJKsLUAhVjaoB6YaJB6ef+Eev/9Zl5c0lkH9rCfR9MdEKLuMP2BHWOk3mJzO6PTChdexm7X6vWJ9zmYqITKrn/n3OiE+qN+gTorkUhzQa70gAAAABJRU5ErkJggg=="
                  alt="icon"
                />
              </div>
            </div>
            <div ph10="" class="shortVideo row flex-column">
              <div lineh26="" class="disCount text-center">低至5折</div>
              <div class="shortVideo_title row">
                <span class="shortVideo_title_title">奇妙助手</span
                ><span ml4="" class="shortVideo_title_desc">增量包</span>
              </div>
              <div class="shortVideo_inner row j-sb a-center shortVideo_inner2">
                <span>购买字数包</span
                ><img
                  class="arrow"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAAAXNSR0IArs4c6QAAASZJREFUWEfN178uBUEUB+DvhoqC6CR6hUbiObQi0XgIJRq8iUjEK9wHkGgVCoXQaXQ6CZNcN1yJuzN7ZrJT7t9vf2f3zM7IwMZoYB41QJt4xVvJw0aDrrGHdxziJhcVCUrJPPwAfOAACdl5RILW8IKlPqhIUHKkcl1hoRQVDUqOfVyWomqAeqFqgYpRNUFFqNqgbFQLUBaqFagz6ht0hGOsdG6pcQf+6ugJtIFnqky0XdlT1FBACX6G0yGULGEuJq9M0zLtYIzVmTpOMWl7q6+sE6YVqDOmBSgLUxuUjakJKsLUAhVjaoB6YaJB6ef+Eev/9Zl5c0lkH9rCfR9MdEKLuMP2BHWOk3mJzO6PTChdexm7X6vWJ9zmYqITKrn/n3OiE+qN+gTorkUhzQa70gAAAABJRU5ErkJggg=="
                  alt="icon"
                />
              </div>
              <div class="shortVideo_inner row j-sb a-center shortVideo_inner2">
                <span>购买图片包</span
                ><img
                  class="arrow"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAAAXNSR0IArs4c6QAAASZJREFUWEfN178uBUEUB+DvhoqC6CR6hUbiObQi0XgIJRq8iUjEK9wHkGgVCoXQaXQ6CZNcN1yJuzN7ZrJT7t9vf2f3zM7IwMZoYB41QJt4xVvJw0aDrrGHdxziJhcVCUrJPPwAfOAACdl5RILW8IKlPqhIUHKkcl1hoRQVDUqOfVyWomqAeqFqgYpRNUFFqNqgbFQLUBaqFagz6ht0hGOsdG6pcQf+6ugJtIFnqky0XdlT1FBACX6G0yGULGEuJq9M0zLtYIzVmTpOMWl7q6+sE6YVqDOmBSgLUxuUjakJKsLUAhVjaoB6YaJB6ef+Eev/9Zl5c0lkH9rCfR9MdEKLuMP2BHWOk3mJzO6PTChdexm7X6vWJ9zmYqITKrn/n3OiE+qN+gTorkUhzQa70gAAAABJRU5ErkJggg=="
                  alt="icon"
                />
              </div>
            </div>

          </div> -->
        </div>
      </div>

      <!-- 直播会员 -->
      <div class="live_vip" ref="liveVip">
        <div class="head_title">直播会员</div>
        <div class="live_list">
          <div class="live_item base">
            <div class="item_title">
              <!--v-if-->
              直播基础版
            </div>
            <div class="vip_list">
              <div class="vip_item">
                <div class="item_price">
                  <span>￥</span><span>9999</span><span>/年</span>
                </div>
                <div class="item_btn">立即咨询</div>
                <div class="item_list">
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>实时驱动、自动播</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>合成时长:</span><span>1500分钟/年</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>60+真人、3D数字人免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>500+声音免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>30+模板免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>100+张背景、插图免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>导入ppt制作免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>储存空间30G</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>视频分辨率： </span><span>1080P</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="live_item advanced">
            <div class="item_title">
              <div class="active"></div>
              直播高级版
            </div>
            <div class="vip_list">
              <div class="vip_item_active_0_0 vip_item">
                <div class="item_price">
                  <span>￥</span><span>4999</span><span>/月</span>
                </div>
                <div class="item_btn">立即购买</div>
                <div class="item_list">
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>实时驱动、自动播</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>合成时长:</span><span>500分钟/月</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>60+真人、3D数字人免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>500+声音免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>30+模板免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>100+张背景、插图免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>导入ppt制作免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>储存空间30G</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>视频分辨率： </span><span>1080P</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>支持真人驱动直播</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><!--v-if--><span>支持实时变声</span>
                  </div>
                </div>
              </div>
              <div class="vip_item">
                <div class="item_price">
                  <span>￥</span><span>49999</span><span>/年</span>
                </div>
                <div class="item_btn">立即咨询</div>
                <div class="item_list">
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>实时驱动、自动播</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>合成时长:</span><span>5000分钟/年</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>60+真人、3D数字人免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>500+声音免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>30+模板免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>100+张背景、插图免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>导入ppt制作免费</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>储存空间30G</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>视频分辨率： </span><span>1080P</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>支持真人驱动直播</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>支持实时变声</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 去除 -->
          <!-- <div class="live_item real">
            <div class="item_title">
              真人驱动直播
            </div>
            <div class="vip_list">
              <div class="vip_item">
                <div class="item_price">
                  <span>￥</span><span>9999</span><span>/年</span>
                </div>
                <div class="item_btn">立即咨询</div>
                <div class="item_list">
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>动捕驱动</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>实时面部+摄像头身体捕捉</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>自定义数字人形象</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>几十款服装配饰</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>多种直播场景</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>十多种互动玩法</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>本地渲染</span>
                  </div>
                  <div class="item_text">
                    <img
                      class="icon"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhZJREFUSEu1ls1rE1EUxX83pUOxfoHGRepCKIrgzoULcSmKiIqLFBciCn6AEUFwIyLi2oUgQhat1IUrFcQS61/gn6DVYsVFJ6BRmppYJSFzZd44mWlmkkyC81bDu/edc9+55703Qo/xY013/mlySpXjApMoOZMulBWWRHg9NsqrbRtkuRuMxAUqvzTXaHIPuKDKSK8iRGgBs9Yod7PjUu7MjRDYP/UkLZ4qbOoFHANUY4SzE5tlLhxbR1Be1euqPFAlMwi4nyuCI8KN3BZ52J7zP0zlDi+HBQ+TkOG0vxOzA6N5gw+DytKjsTXLYq/bE0NgV3ValYvDyNKVRJiZ2CqXxFixwZd+bukGVKnDzTlYWYPHZyC70ct03TVmsUvsVS2ow6NhqnfB80/gU8VbXZyCE/sCJMlwTZarOo9yLEzQdLyKdvyrJo78Wx2mQuC7s1C6DONWKFt4I3ZVP6qyJwxypAgLX+HqIbh1OArvgudnYem7F3PBn50P5Ak5alHsFa0ptGtttGD/faj+9tKuHIQ7RwOSpOCmD1CPELiB0nsoPIeWs55kEPCAIEYiQ/IOCi8CknMH4O3n/rKEBRVhMbbJflIniT/fTfNIt0yT+9i0U67E4G4PXJsmOWjzC3C7BJPboZiPuiXOxu2D5gaTXBXquSLxEP+qcFekftmZXaR5Xfv7TvXB8UlSfTJ9klQf/bBN/sdvy18CfB2ccrxqoAAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      class="icon_hover"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAj1JREFUSEu1lj1MFEEUx/9vdhUhR06hEC8qKB5aepJIAxc0UhgTjb0WJn4UGowUEi242GCpoDEBjB+J8aPxK1GLKzRqY3Utd4SIqNeoR3HGU8zNM7PLjuvt3gcXd8uZN//fm/97M7OECl9P/8z631IeYIl9TNwJILIUniWmWRJ4tkKIJ+9eRT+VkyG/idjAfAQ/CxcAHGFmo1ISRFQEcBOrGhOp5MZsaawHEIvP7GdZvAOguZKwz1yehHEo9Tr61D33DyDWmxlk8CWAxTLFl8JJEuhM6m3XuLNeA+zM5aP6xbWkJCEOOjuxAMpzLvyYrsOWchvNU2PTNlUTG9CXnmLmo/XZ4r+KiK6n3mw9RqoVF4tyrlq3lIO3rDGQGG5DOGxg6Pxn5BZUUwGqu1YaooN2xNMnpeSr9WSvxKfGN2BzR4O1/OxIFsmXeS0lBJ2iWG/6OYP3ugGmASujbzk7G7+vtcXA5Nhf8dn3v3D4xAcUCqzDCfSCtvdNp8Hocovcv9GOaGcDbt3N4crEV4++EleZb2q3M1fix09/1Pa4CBkFyIMRcgZNE0g+3oLVYfsA376Xw+VrX/SamsWtQuC7B6DG9/SHcDERgWnax8SBLEvcBfBYpOYGdjVjdGSdhjx4uICd3U3VbXEbSsj4FtmJKYU442U9L6mWVeRqbVpqV63iimW1aS0HbXc8hHNDazE3v4jhRNbbLT59rA+amgv0qrAAQV92FiTI69qxMNAHR0OCfDI1JMhH391x/+O35Q9CozcQNO4SxgAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>远程培训2次</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Member",
  data() {
    return {
      hoveredItem: null,
      activeIndex: 0,
      menuItems: [
        { title: "视频制作会员", ref: "videoVip" },
        { title: "直播会员", ref: "liveVip" },
        // 可以添加其他会员类型
      ],
    }
  },
  methods: {
    scrollToSection(index) {
      this.activeIndex = index
      const targetRef = this.menuItems[index].ref
      const element = this.$refs[targetRef]
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.member-new {
  width: 100%;
  background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/bg.28cda2db.png)
      0 top repeat-x,
    #f3f7fd;
  background-size: 100%;
}

.main {
  width: 100%;
  padding-top: 60px;
  min-width: 1200px;
  padding-bottom: 120px;
}

.head_vip {
  width: 100%;
  padding-top: 56px;

  .menu_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu {
    // width: 470px;
    height: 56px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    border: 2px solid #ffffff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 2px;

    .menu_item:first-child {
      border-radius: 8px;
    }

    .menu_item {
      width: 154px;
      height: 48px;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu_item_active {
      background: linear-gradient(134deg, #00a4ff 0%, #006eff 100%);
      border-radius: 8px;
      color: #fff;
    }
  }
}

.limit {
  display: inline-block;
  position: relative;
}

.video_vip {
  .head_title {
    margin-top: 60px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #222831;
  }

  .vip_list {
    margin-top: 60px;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: center;

    .videoMemberInner {
      width: 282px;
    }

    .vip_item {
      position: relative;
      width: 384px;
      height: 720px;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #dde1ea;
      display: flex;
      flex-flow: column nowrap;
      margin-right: 24px;
      cursor: pointer;
      caret-color: #fd5555;

      .item_head {
        margin-top: 40px;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #222831;
      }

      .item_price {
        position: relative;
        height: 140px;
        padding-top: 48px;

        .free {
          font-size: 32px;
          color: #222831;
          font-weight: 600;
          text-align: center;

          span {
            font-size: 20px;
          }
        }

        .nofree {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .type {
            font-weight: 600;
            color: #222831;
            font-size: 20px;
          }

          .money {
            font-weight: 600;
            color: #222831;
            font-size: 44px;
          }

          .unit {
            align-self: flex-end;
            margin-left: 4px;
            font-weight: 500;
            font-size: 20px;
            color: #222831;
          }
        }
      }

      .item_btn {
        margin: 0 auto;
        width: 234px;
        height: 64px;
        line-height: 64px;
        background: #ffffff;
        border-radius: 12px;
        border: 1px solid #dde1ea;
        font-size: 20px;
        text-align: center;
        color: #006eff;
        font-weight: 600;
        cursor: pointer;
      }

      .item_list {
        margin-top: 32px;
        margin-left: 24px;
        margin-bottom: 24px;

        .item_text {
          width: 100%;
          margin-bottom: 16px;
          display: flex;
          align-items: center;

          .icon {
            display: inline-block;
          }

          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            color: #222831;
          }
        }
      }

      &:nth-child(2):hover {
        background: linear-gradient(
          135deg,
          #004aff 0%,
          #00a4ff 53%,
          #006eff 100%
        );
      }

      &:nth-child(3):hover {
        background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/nk_bg.d49d7016.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .vip_item_active_0 {
      background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/nk_bg1.2a999178.png)
        no-repeat;
      background-size: 100% 100%;
      border: 1px solid transparent;
      box-shadow: 0 4px 24px #e3ebfd;
    }
  }
}

.live_vip {
  .head_title {
    margin-top: 80px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #222831;
  }
  .live_list {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .live_item {
      margin-right: 24px;
      .item_title {
        width: 288px;
        height: 116px;
        line-height: 116px;
        background: #ffffff;
        border-radius: 16px 16px 0 0;
        border: 1px solid #dde1ea;
        border-bottom: 0;
        overflow: hidden;
        text-align: center;
        position: relative;
        font-size: 28px;
        font-weight: 600;
        color: #222831;
      }

      .vip_list {
        display: flex;
        flex-flow: row nowrap;
        .vip_item {
          width: 288px;
          height: 686px;
          background: #ffffff;
          border: 1px solid #dde1ea;
          border-radius: 0 0 16px 16px;

          .item_price {
            height: 148px;
            line-height: 148px;
            text-align: center;
            color: #222831;
            font-size: 20px;
          }
          .item_btn {
            margin: 0 auto;
            width: 246px;
            height: 64px;
            line-height: 64px;
            background: #ffffff;
            border-radius: 12px;
            border: 1px solid #dde1ea;
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            color: #006eff;
            cursor: pointer;
          }

          .item_list {
            margin-top: 32px;
            margin-left: 24px;
            margin-bottom: 24px;
            .item_text {
              width: 100%;
              margin-bottom: 16px;
              display: flex;
              align-items: center;

              .icon {
                display: inline-block;
              }

              img {
                width: 12px;
                height: 12px;
                margin-right: 5px;
              }

              span {
                font-size: 14px;
                font-weight: 400;
                color: #222831;
              }
            }
          }
        }
      }
    }

    .advanced {
      .item_title {
        width: 576px;
      }
    }
  }
}
</style>

<template>
  <section class="step2">
    <h2 class="title">深度合作客户超500+</h2>
    <!-- <div class="client-grid">
        <div v-for="(client, index) in clients" :key="index" class="client-item">
          <img :src="client.logo" :alt="client.name" class="client-logo">
        </div>
      </div> -->
    <div class="logo-box">
      <img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo1.cd258389.png"
        class="logo logo1"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo1.cd258389.png"
        class="logo logo1"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo1.cd258389.png"
        class="logo logo1"
      />
    </div>

    <div class="logo-box">
      <img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo2.9e222230.png"
        class="logo logo2"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo2.9e222230.png"
        class="logo logo2"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo2.9e222230.png"
        class="logo logo2"
      />
    </div>

    <div class="logo-box">
      <img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo3.b974642d.png"
        class="logo logo3"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo3.b974642d.png"
        class="logo logo3"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo3.b974642d.png"
        class="logo logo3"
      />
    </div>

    <div class="logo-box">
      <img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo4.ec0fe8b6.png"
        class="logo logo4"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo4.ec0fe8b6.png"
        class="logo logo4"
      /><img
        src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo4.ec0fe8b6.png"
        class="logo logo4"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "ClientSection",
  data() {
    return {
      clients: [
        {
          name: "腾讯云",
          logo: "https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon1.e18805ab.png",
        },
        {
          name: "阿里云",
          logo: "https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/type_icon2.9cd964e0.png",
        },
        { name: "抖音", logo: "path_to_douyin_logo" },
        { name: "中国移动", logo: "path_to_china_mobile_logo" },
        { name: "中国电信", logo: "path_to_china_telecom_logo" },
        { name: "中国工商银行", logo: "path_to_icbc_logo" },
        // 添加更多客户...
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.client-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.title {
  font-weight: 600;
  font-size: 44px;
  color: #222831;
  line-height: 60px;
  text-align: center;
  margin-bottom: 40px;
}

.step2 {
  width: 100%;
  height: 836px;
  overflow: hidden;
  background-image: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/bg.ee5984c9.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}

@keyframes move-20ddd0a9 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.logo-box {
  display: flex;
  margin-bottom: 40px;
  .logo {
    height: 80px;
    width: auto;
    padding-right: 36px;
    animation: move-20ddd0a9 10s linear infinite normal none;
  }
  .logo1 {
    animation-duration: 40s;
  }

  .logo2 {
    animation-duration: 52s;
  }

  .logo3 {
    animation-duration: 44s;
  }

  .logo4 {
    animation-duration: 48s;
  }
}

.client-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 30px;
  justify-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.client-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.client-item:hover {
  transform: translateY(-5px);
}

.client-logo {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
</style>

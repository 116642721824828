<template>
  <div class="main-header-root" :class="{ scrolled: isScrolled }">
    <div class="main-header">
      <div class="header-left">
        <div class="header-logo">
          <img alt="Vue logo"
            src="~@/assets/logo.png" />
          <!-- <img alt="Vue logo"
            src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/logo-light.d36a8e12.png" /> -->
        </div>
        <!-- <div class="header-link">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            :background-color="isScrolled ? '#ffffff' : '#040d1e'"
            :text-color="isScrolled ? '#333333' : '#ffffff'"
            active-text-color="#006eff"
            router
          >
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/makehome">视频制作</el-menu-item>
            <el-menu-item index="/broadcastCenter">直播中心</el-menu-item>
            <el-menu-item index="/memberIndex">会员中心</el-menu-item>
            <el-menu-item index="/helpCenter">帮助中心</el-menu-item>
          </el-menu>
        </div> -->

        <div class="header-link">
          <nav class="nav-menu">
            <!--  :style="{
                color: isScrolled ? '#333333' : '#ffffff',
                backgroundColor: isScrolled ? '#ffffff' : '#040d1e',
              }" -->
            <a v-for="(item, index) in menuItems" :key="index" :href="item.path"
              :class="{ active: activeIndex === item.path }" @click.prevent="handleSelect(item.path)">
              {{ item.label }}
            </a>
          </nav>
        </div>
      </div>
      <div class="header-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainNavbar",
  props: {
    isScrolled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: "/",
      menuItems: [
        { label: "首页", path: "/" },
        { label: "视频制作", path: "/makehome" },
        { label: "直播中心", path: "/broadcastCenter" },
        { label: "会员中心", path: "/memberIndex" },
        { label: "帮助中心", path: "/downloadCenter" },
        // { label: "帮助中心", path: "/helpCenter" },
      ],
    }
  },
  methods: {
    handleSelect(path) {
      if (this.$route.path !== path) {
        this.$router.push(path).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err
          }
        })
      }
      this.activeIndex = path // 更新激活项
    },
  },
  mounted() {
    // 设置初始激活项
    this.activeIndex = this.$route.path
  },
  watch: {
    // 监听路由变化，更新激活项
    "$route.path"(newPath) {
      this.activeIndex = newPath
    },
  },
}
</script>

<style scoped lang="scss">
/* 样式重置 */
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.main-header-root {
  width: 100%;
  height: 72px;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  // background-color: rgba(0,0,0,0);
  background-color: #040d1e;
  z-index: 9999;
  transition: all 0.3s ease;

  .header-link {
    margin-left: 20px;
    font-size: 20px;

    .nav-menu {
      display: flex;

      a {
        margin-right: 20px;
        text-decoration: none;
        color: #fff;
        transition: color 0.3s, background-color 0.3s;
        font-size: 16px;

        &.active {
          color: #006eff;
        }
      }
    }
  }

  &.scrolled {
    // background-color: rgba(4, 13, 30, 0.9); // 半透明背景
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // height: 60px; // 稍微减小高度

    // .main-header {
    //   height: 50px;
    // }

    .header-logo {
      width: 90px; // 稍微缩小 logo
      height: 30px;
    }

    .nav-menu a {
      color: #333;
    }
  }

  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
  }

  .header-left {
    display: flex;
    align-items: center;

    .header-logo {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 80px;
      width: 104px;
      height: 34px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <section class="step5">
    <h2 class="title">无需专业团队，打造7*24小时数字人直播间</h2>

    <div class="list" @mouseenter="pauseAnimation" @mouseleave="resumeAnimation">
      <div class="list_inner" :style="{ animationPlayState: animationState }">
        <div v-for="(item, index) in videoItems" :key="index" class="item">
          <div class="video_box">
            <div class="inner">
              <video :ref="`video${index}`" preload="none" :poster="item.poster" :src="item.src"></video>
              <div class="step1_video_bt" @click="playVideo(index)"></div>
            </div>
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div v-if="showModal" class="video-modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <video :src="currentVideoSrc" controls autoplay></video>
        <button class="close-button" @click="closeModal">&times;</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VideoSection",
  data() {
    return {
      animationState: 'running',
      showModal: false,
      currentVideoSrc: '',
      videoItems: [
        {
          name: "文旅行业案例",
          poster: "https://mobvoi-digitalhuman-public.weta365.com/1768097114409275392.jpg",
          src: "https://mobvoi-digitalhuman-public.weta365.com/1768096751509704704.mp4"
        },
        {
          name: "本地生活客户案例",
          poster: "https://mobvoi-digitalhuman-public.weta365.com/1768093706411446272.jpg",
          src: "https://mobvoi-digitalhuman-public.weta365.com/1768093978470780928.mp4"
        },
        {
          name: "3C行业客户案例",
          poster: "https://mobvoi-digitalhuman-public.weta365.com/1768096354950844416.jpg",
          src: "https://mobvoi-digitalhuman-public.weta365.com/1768096494256263168.mp4"
        },
        {
          name: "快消行业客户案例",
          poster: "https://mobvoi-digitalhuman-public.weta365.com/1768094262974615552.jpg",
          src: "https://mobvoi-digitalhuman-public.weta365.com/1768096016474705920.mp4"
        },
        {
          name: "航空行业3D案例",
          poster: "https://mobvoi-digitalhuman-public.weta365.com/1768097764392177664.jpg",
          src: "https://mobvoi-digitalhuman-public.weta365.com/1768097637543841792.mp4"
        },
        {
          name: "娱乐行业3D案例",
          poster: "https://mobvoi-digitalhuman-public.weta365.com/1768097336359260160.jpg",
          src: "https://mobvoi-digitalhuman-public.weta365.com/1768097517326700544.mp4"
        }
      ]
    }
  },
  methods: {
    pauseAnimation() {
      this.animationState = 'paused';
    },
    resumeAnimation() {
      this.animationState = 'running';
    },
    playVideo(index) {
      this.currentVideoSrc = this.videoItems[index].src;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.currentVideoSrc = '';
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 44px;
  color: #222831;
  line-height: 60px;
  text-align: center;
  margin-bottom: 40px;
}

.step5 {
  width: 100%;
  height: 1073px;
  overflow: hidden;
  background: rgba(242, 248, 255, 0.6);
  padding-top: 100px;
  position: relative;
}

@keyframes move-2108172c {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.list {
  .list_inner {
    display: flex;
    animation: move-2108172c 48s linear infinite;
    width: 2280px;

    .item {
      margin-right: 30px;

      .video_box {
        width: 350px;
        height: 606px;
        border-radius: 24px;
        padding: 7px;
        background: transparent;

        .inner {
          width: 100%;
          height: 100%;
          background: transparent;
          border-radius: 20px;
          padding: 7px;
          position: relative;

          video {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
          }

          .step1_video_bt {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/newVideo.ca0ec33a.png);
            background-size: cover;
            position: absolute;
            text-align: center;
            left: 50%;
            top: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }

      .name {
        // font-family: HarmonyOS_Sans_SC_Medium;
        font-size: 22px;
        color: #000;
        line-height: 30px;
        text-align: center;
        margin-top: 14px;
      }
    }
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    position: relative;
    width: 405px;
    height: 720px;
    // width: 90%;
    // max-width: 640px;
    background-color: #000;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);

    video {
      width: 100%;
      display: block;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      font-size: 24px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>

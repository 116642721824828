<template>
  <div class="digital-human-applications">
    <h2 class="main-title">数字人8大应用场景，省钱省时间</h2>
    <div class="content">
      <div class="video-section">
        <video ref="videoPlayer" :src="currentApplication.videoSrc" controls muted></video>
      </div>
      <div class="info-section">
        <h3>{{ currentApplication.title }}</h3>
        <p>{{ currentApplication.description }}</p>
        <div class="categories">
          <button v-for="(app, index) in applications" :key="index" @click="setCurrentApplication(index)"
            :class="{ active: currentIndex === index }">
            {{ app.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalHumanApplications",
  data() {
    return {
      currentIndex: 0,
      applications: [
        {
          title: "新闻播报",
          description: "AI主播全天候在岗，可随时为网络平台播报",
          // videoSrc: '/videos/news_report.mp4'
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1689116706087243776.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087204688236544.png",
        },
        {
          title: "科普服务",
          description: "利用AI数字人进行科普知识传播",
          // videoSrc: '/videos/science_service.mp4'
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1645428773820174336.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087185620930560.png",
        },
        {
          title: "培训视频",
          description:
            "培训师需要大量高频地制作培训视频，使用数字人制作视频，就能随时随地修改，高效便捷，还能克隆自己的形象来做。",
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1645428790551252992.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087208945455104.png",
        },
        {
          title: "线上客服",
          description:
            "以数字人担当智能客服，引导用户办理业务，回答客户提问，能为B端用户解决很多实际问题，提供更好的服务。",
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1645428868544335872.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087200800116736.png",
        },
        // 



        {
          title: "带货种草",
          description:
            "带货种草是趋势，数字人表达清晰准确，适应多种带货场景，带货能力不比真人差。",
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1689116724525404160.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087183565721600.png",
        },


        {
          title: "产品宣传",
          description:
            "文旅、政企等需要大量宣传的行业，使用AI数字人宣传，形式新鲜，讲解精准，能够更好地吸引关注。",
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1689114891216097280.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087190591180800.png",
        },


        {
          title: "企业代言",
          description:
            "可通过AI数字人打造品牌虚拟代言人，对企业文化、活动产品等进行详细讲解，解决了真人代言人的人设崩塌等各类问题。",
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1645428898000932864.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087206760222720.png",
        },


        {
          title: "金融宣传",
          description:
            "银行、保险、证券、基金，在宣传、科普视频里加入真人数字人形象，既有真人服务的体验感，也能大幅降低人工成本。",
          videoSrc:
            "https://mobvoi-digitalhuman-public.weta365.com/1645428916183240704.mp4",
          poster:
            "https://mobvoi-digitalhuman-public.weta365.com/1646087198648438784.png",
        },


        // 添加更多应用场景...
      ],
      autoPlayInterval: null,
    }
  },
  computed: {
    currentApplication() {
      return this.applications[this.currentIndex]
    },
  },
  methods: {
    setCurrentApplication(index) {
      this.currentIndex = index
      this.$refs.videoPlayer.load() // 重新加载视频
      // this.$refs.videoPlayer.play() // 自动播放新视频
      this.$refs.videoPlayer.play().catch(e => console.log("Auto-play was prevented"))
    },
    startAutoPlay() {
      this.autoPlayInterval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.applications.length
        this.$refs.videoPlayer.load()
        this.$refs.videoPlayer.play().catch(e => console.log("Auto-play was prevented"))
      }, 30000) // 每10秒切换一次
    },
    stopAutoPlay() {
      clearInterval(this.autoPlayInterval)
    },
  },
  mounted() {
    this.startAutoPlay()
  },
  beforeDestroy() {
    this.stopAutoPlay()
  },
}
</script>

<style scoped>
.digital-human-applications {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-title {
  font-size: 48px;
  line-height: 60px;
  padding: 80px 0 60px;
  text-align: center;
  font-weight: 700;
  color: #222831;
}

.content {
  display: flex;
  justify-content: space-between;
  height: 450px;
}

.video-section {
  width: 600px;
  height: 340px;
}

.video-section video {
  width: 100%;
  border-radius: 8px;
}

.info-section {
  width: 40%;

  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.categories button {
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.categories button.active {
  background-color: #007bff;
  color: white;
}

.categories button:hover {
  background-color: #e0e0e0;
}
</style>

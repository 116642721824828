import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./plugins/element.js"
import "./styles/global.scss" //样式初始化

// import VueVideoPlayer from 'vue-video-player'

// // 导入 video.js 样式
// import 'video.js/dist/video-js.css'

// // 使用 VueVideoPlayer 插件
// Vue.use(VueVideoPlayer)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // 如果路由有定义 `meta.title`，则更新页面标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

<template>
  <div class="priceReviewWarpper">
    <div class="content-title">数字人直播，1/10的价格，效果媲美真人</div>

    <div class="content m-auto width1200 row">
      <span class="left"
        ><div class="left_inner">
          <div class="rightGap"></div>
          <div class="top">
            <img
              class="all100"
              src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/priceLeft.dee75b16.png"
              alt="person"
            />
          </div>
          <div class="bom">
            <div class="title">传统直播</div>
            <p class="desc">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAhBJREFUSEut1r9rE2Ecx/H39zRixQ6F4iSUiwlIl1r8A9xdHBSqSXPiDzqkqLNDC1UnBx0EHSRK06YOorg4OeqsW7cqLo5axCGg3FeexISk9+N57qm3Xnhe+dzzfT53os3mUX7tPkQ5D/wgCO7J+uZz9nlpo3EG4gcIVdD3lGhKa+uraFR7inJ9bH0JVqW9edfX1Kh2DuQlqqXhGsIn1junRRu178BUYnFPNBUbLH6QEwbcAcqpaQqiuRgohyamRS8vXiGOn2U+PkfUgoHIY2l3lsVAGi2uoPEdX9QBe01YWZC1tT89cD9oEcw4Q9AHLYolwCKoD5YKuqHyArgwds5GB0BkuGd752LskY7etA5S1oTlYJkJnQcp8fezkw1+mpmwMGpJ5gz297S+heql7G6VbcqVOXPObP3rkDCliNNWdWykXNA6+ok9tL9lcqbUMVlBNBW0J5Nt0Fmf7k2AdoxXlKsX+bJz26fw93Sp9TH2sME0WsshZZBG3hYWLOOcFUX/vQ/9MOdyGEkqva8rid9lFjH9PbMdantSuSntziPz1fYRZT514hzryimpSJdjwbRoVP+J6mQCLIg5oaXgpEn4FuXsGOiJ5aLCN8LqjOi12gy/5Q2qp0xPI/KEsHLLtme2ktaofgO4j+phDKYHFmRj40N/SlWFq/WQYGJXWi3zYfxfLl1aOkK3e5ww/DwI8BcATledaHAUEgAAAABJRU5ErkJggg=="
                alt=""
              />
              真人主播人力成本昂贵
            </p>
            <p class="desc">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAhBJREFUSEut1r9rE2Ecx/H39zRixQ6F4iSUiwlIl1r8A9xdHBSqSXPiDzqkqLNDC1UnBx0EHSRK06YOorg4OeqsW7cqLo5axCGg3FeexISk9+N57qm3Xnhe+dzzfT53os3mUX7tPkQ5D/wgCO7J+uZz9nlpo3EG4gcIVdD3lGhKa+uraFR7inJ9bH0JVqW9edfX1Kh2DuQlqqXhGsIn1junRRu178BUYnFPNBUbLH6QEwbcAcqpaQqiuRgohyamRS8vXiGOn2U+PkfUgoHIY2l3lsVAGi2uoPEdX9QBe01YWZC1tT89cD9oEcw4Q9AHLYolwCKoD5YKuqHyArgwds5GB0BkuGd752LskY7etA5S1oTlYJkJnQcp8fezkw1+mpmwMGpJ5gz297S+heql7G6VbcqVOXPObP3rkDCliNNWdWykXNA6+ok9tL9lcqbUMVlBNBW0J5Nt0Fmf7k2AdoxXlKsX+bJz26fw93Sp9TH2sME0WsshZZBG3hYWLOOcFUX/vQ/9MOdyGEkqva8rid9lFjH9PbMdantSuSntziPz1fYRZT514hzryimpSJdjwbRoVP+J6mQCLIg5oaXgpEn4FuXsGOiJ5aLCN8LqjOi12gy/5Q2qp0xPI/KEsHLLtme2ktaofgO4j+phDKYHFmRj40N/SlWFq/WQYGJXWi3zYfxfLl1aOkK3e5ww/DwI8BcATledaHAUEgAAAABJRU5ErkJggg=="
                alt=""
              />
              无法长时间工作、风险高
            </p>
            <p class="desc">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAhBJREFUSEut1r9rE2Ecx/H39zRixQ6F4iSUiwlIl1r8A9xdHBSqSXPiDzqkqLNDC1UnBx0EHSRK06YOorg4OeqsW7cqLo5axCGg3FeexISk9+N57qm3Xnhe+dzzfT53os3mUX7tPkQ5D/wgCO7J+uZz9nlpo3EG4gcIVdD3lGhKa+uraFR7inJ9bH0JVqW9edfX1Kh2DuQlqqXhGsIn1junRRu178BUYnFPNBUbLH6QEwbcAcqpaQqiuRgohyamRS8vXiGOn2U+PkfUgoHIY2l3lsVAGi2uoPEdX9QBe01YWZC1tT89cD9oEcw4Q9AHLYolwCKoD5YKuqHyArgwds5GB0BkuGd752LskY7etA5S1oTlYJkJnQcp8fezkw1+mpmwMGpJ5gz297S+heql7G6VbcqVOXPObP3rkDCliNNWdWykXNA6+ok9tL9lcqbUMVlBNBW0J5Nt0Fmf7k2AdoxXlKsX+bJz26fw93Sp9TH2sME0WsshZZBG3hYWLOOcFUX/vQ/9MOdyGEkqva8rid9lFjH9PbMdantSuSntziPz1fYRZT514hzryimpSJdjwbRoVP+J6mQCLIg5oaXgpEn4FuXsGOiJ5aLCN8LqjOi12gy/5Q2qp0xPI/KEsHLLtme2ktaofgO4j+phDKYHFmRj40N/SlWFq/WQYGJXWi3zYfxfLl1aOkK3e5ww/DwI8BcATledaHAUEgAAAABJRU5ErkJggg=="
                alt=""
              />
              需要搭建场地、灯光、布景
            </p>
            <p class="desc">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAhBJREFUSEut1r9rE2Ecx/H39zRixQ6F4iSUiwlIl1r8A9xdHBSqSXPiDzqkqLNDC1UnBx0EHSRK06YOorg4OeqsW7cqLo5axCGg3FeexISk9+N57qm3Xnhe+dzzfT53os3mUX7tPkQ5D/wgCO7J+uZz9nlpo3EG4gcIVdD3lGhKa+uraFR7inJ9bH0JVqW9edfX1Kh2DuQlqqXhGsIn1junRRu178BUYnFPNBUbLH6QEwbcAcqpaQqiuRgohyamRS8vXiGOn2U+PkfUgoHIY2l3lsVAGi2uoPEdX9QBe01YWZC1tT89cD9oEcw4Q9AHLYolwCKoD5YKuqHyArgwds5GB0BkuGd752LskY7etA5S1oTlYJkJnQcp8fezkw1+mpmwMGpJ5gz297S+heql7G6VbcqVOXPObP3rkDCliNNWdWykXNA6+ok9tL9lcqbUMVlBNBW0J5Nt0Fmf7k2AdoxXlKsX+bJz26fw93Sp9TH2sME0WsshZZBG3hYWLOOcFUX/vQ/9MOdyGEkqva8rid9lFjH9PbMdantSuSntziPz1fYRZT514hzryimpSJdjwbRoVP+J6mQCLIg5oaXgpEn4FuXsGOiJ5aLCN8LqjOi12gy/5Q2qp0xPI/KEsHLLtme2ktaofgO4j+phDKYHFmRj40N/SlWFq/WQYGJXWi3zYfxfLl1aOkK3e5ww/DwI8BcATledaHAUEgAAAABJRU5ErkJggg=="
                alt=""
              />
              需要专业直播团队来操作
            </p>
            <p class="desc">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAhBJREFUSEut1r9rE2Ecx/H39zRixQ6F4iSUiwlIl1r8A9xdHBSqSXPiDzqkqLNDC1UnBx0EHSRK06YOorg4OeqsW7cqLo5axCGg3FeexISk9+N57qm3Xnhe+dzzfT53os3mUX7tPkQ5D/wgCO7J+uZz9nlpo3EG4gcIVdD3lGhKa+uraFR7inJ9bH0JVqW9edfX1Kh2DuQlqqXhGsIn1junRRu178BUYnFPNBUbLH6QEwbcAcqpaQqiuRgohyamRS8vXiGOn2U+PkfUgoHIY2l3lsVAGi2uoPEdX9QBe01YWZC1tT89cD9oEcw4Q9AHLYolwCKoD5YKuqHyArgwds5GB0BkuGd752LskY7etA5S1oTlYJkJnQcp8fezkw1+mpmwMGpJ5gz297S+heql7G6VbcqVOXPObP3rkDCliNNWdWykXNA6+ok9tL9lcqbUMVlBNBW0J5Nt0Fmf7k2AdoxXlKsX+bJz26fw93Sp9TH2sME0WsshZZBG3hYWLOOcFUX/vQ/9MOdyGEkqva8rid9lFjH9PbMdantSuSntziPz1fYRZT514hzryimpSJdjwbRoVP+J6mQCLIg5oaXgpEn4FuXsGOiJ5aLCN8LqjOi12gy/5Q2qp0xPI/KEsHLLtme2ktaofgO4j+phDKYHFmRj40N/SlWFq/WQYGJXWi3zYfxfLl1aOkK3e5ww/DwI8BcATledaHAUEgAAAABJRU5ErkJggg=="
                alt=""
              />
              需要专业直播团队来操作
            </p>
          </div>
        </div></span
      ><span class="right"
        ><div class="top">
          <img
            class="all100"
            src="https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/priceRight.3c59802b.png"
            alt="person"
          />
        </div>
        <div class="bom" style="">
          <div class="title">数字人直播</div>
          <p class="desc">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAcRJREFUSEvF1r9Kw1AUBvDv3Ko4iIs4+A8EfYGkguDkC6ig4CA4OLnaTuoLdKlR6+bSycmOugl2l7abiy7qKoiIHVrbIylJjG2T3NyENFNIbnJ/+XLOTQgD2jSDlwDkmaET8EaEfDVLRRqERzd4rc0oARhxzy8E9hIHeWFMGAGPiYL8MFZSX4mBJDBmROVEQFIYoCGGsOKAtAJPoolpMYanyj7V4yp2aUwKW9UDuqHVIo9+fuCSGbsW4psEjmoZuoiKCovpFLZmcI4Zh92Tk0CulqFjVZR2xuvcwnV3a3fdryGsZOzjpJ3wKwNz/SZWRali7ITemTHhlURYlIlBGyVmDPuk25PMX0IGXzFjx+/VyKKiJOOA0qc81WI8gDETBRUHxlqtAd3gxTZwD8asCioujAMyd1RRUWump7vdB8Ki4sb8S8iGSaOo8/uwodpNnl3d70S6wAutH5SDaipg0fRsbd869ToZEaWE6fvK3EBFVCMlsFnJ0K3KZyfw9yMkKhImMCH7CSVRkTHSIHNgACoWTCiQC3UHxrzz7SHUBWFbtWZ8F0aZIlwu8HiziSwTdAAvBJxXs/Qsc63MmMCilrlJnGN+AZ+1EftHvysCAAAAAElFTkSuQmCC"
              alt=""
              style="width: 18px; height: 13px"
            />
            数字人主播便宜，节省90%成本
          </p>
          <p class="desc">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAcRJREFUSEvF1r9Kw1AUBvDv3Ko4iIs4+A8EfYGkguDkC6ig4CA4OLnaTuoLdKlR6+bSycmOugl2l7abiy7qKoiIHVrbIylJjG2T3NyENFNIbnJ/+XLOTQgD2jSDlwDkmaET8EaEfDVLRRqERzd4rc0oARhxzy8E9hIHeWFMGAGPiYL8MFZSX4mBJDBmROVEQFIYoCGGsOKAtAJPoolpMYanyj7V4yp2aUwKW9UDuqHVIo9+fuCSGbsW4psEjmoZuoiKCovpFLZmcI4Zh92Tk0CulqFjVZR2xuvcwnV3a3fdryGsZOzjpJ3wKwNz/SZWRali7ITemTHhlURYlIlBGyVmDPuk25PMX0IGXzFjx+/VyKKiJOOA0qc81WI8gDETBRUHxlqtAd3gxTZwD8asCioujAMyd1RRUWump7vdB8Ki4sb8S8iGSaOo8/uwodpNnl3d70S6wAutH5SDaipg0fRsbd869ToZEaWE6fvK3EBFVCMlsFnJ0K3KZyfw9yMkKhImMCH7CSVRkTHSIHNgACoWTCiQC3UHxrzz7SHUBWFbtWZ8F0aZIlwu8HiziSwTdAAvBJxXs/Qsc63MmMCilrlJnGN+AZ+1EftHvysCAAAAAElFTkSuQmCC"
              alt=""
              style="width: 18px; height: 13px"
            />
            随时随地开播，24小时直播
          </p>
          <p class="desc">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAcRJREFUSEvF1r9Kw1AUBvDv3Ko4iIs4+A8EfYGkguDkC6ig4CA4OLnaTuoLdKlR6+bSycmOugl2l7abiy7qKoiIHVrbIylJjG2T3NyENFNIbnJ/+XLOTQgD2jSDlwDkmaET8EaEfDVLRRqERzd4rc0oARhxzy8E9hIHeWFMGAGPiYL8MFZSX4mBJDBmROVEQFIYoCGGsOKAtAJPoolpMYanyj7V4yp2aUwKW9UDuqHVIo9+fuCSGbsW4psEjmoZuoiKCovpFLZmcI4Zh92Tk0CulqFjVZR2xuvcwnV3a3fdryGsZOzjpJ3wKwNz/SZWRali7ITemTHhlURYlIlBGyVmDPuk25PMX0IGXzFjx+/VyKKiJOOA0qc81WI8gDETBRUHxlqtAd3gxTZwD8asCioujAMyd1RRUWump7vdB8Ki4sb8S8iGSaOo8/uwodpNnl3d70S6wAutH5SDaipg0fRsbd869ToZEaWE6fvK3EBFVCMlsFnJ0K3KZyfw9yMkKhImMCH7CSVRkTHSIHNgACoWTCiQC3UHxrzz7SHUBWFbtWZ8F0aZIlwu8HiziSwTdAAvBJxXs/Qsc63MmMCilrlJnGN+AZ+1EftHvysCAAAAAElFTkSuQmCC"
              alt=""
              style="width: 18px; height: 13px"
            />
            只需一部手机、一台电脑
          </p>
          <p class="desc">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAcRJREFUSEvF1r9Kw1AUBvDv3Ko4iIs4+A8EfYGkguDkC6ig4CA4OLnaTuoLdKlR6+bSycmOugl2l7abiy7qKoiIHVrbIylJjG2T3NyENFNIbnJ/+XLOTQgD2jSDlwDkmaET8EaEfDVLRRqERzd4rc0oARhxzy8E9hIHeWFMGAGPiYL8MFZSX4mBJDBmROVEQFIYoCGGsOKAtAJPoolpMYanyj7V4yp2aUwKW9UDuqHVIo9+fuCSGbsW4psEjmoZuoiKCovpFLZmcI4Zh92Tk0CulqFjVZR2xuvcwnV3a3fdryGsZOzjpJ3wKwNz/SZWRali7ITemTHhlURYlIlBGyVmDPuk25PMX0IGXzFjx+/VyKKiJOOA0qc81WI8gDETBRUHxlqtAd3gxTZwD8asCioujAMyd1RRUWump7vdB8Ki4sb8S8iGSaOo8/uwodpNnl3d70S6wAutH5SDaipg0fRsbd869ToZEaWE6fvK3EBFVCMlsFnJ0K3KZyfw9yMkKhImMCH7CSVRkTHSIHNgACoWTCiQC3UHxrzz7SHUBWFbtWZ8F0aZIlwu8HiziSwTdAAvBJxXs/Qsc63MmMCilrlJnGN+AZ+1EftHvysCAAAAAElFTkSuQmCC"
              alt=""
              style="width: 18px; height: 13px"
            />
            一次配置即可，小白也能轻松上手
          </p>
          <p class="desc">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAcRJREFUSEvF1r9Kw1AUBvDv3Ko4iIs4+A8EfYGkguDkC6ig4CA4OLnaTuoLdKlR6+bSycmOugl2l7abiy7qKoiIHVrbIylJjG2T3NyENFNIbnJ/+XLOTQgD2jSDlwDkmaET8EaEfDVLRRqERzd4rc0oARhxzy8E9hIHeWFMGAGPiYL8MFZSX4mBJDBmROVEQFIYoCGGsOKAtAJPoolpMYanyj7V4yp2aUwKW9UDuqHVIo9+fuCSGbsW4psEjmoZuoiKCovpFLZmcI4Zh92Tk0CulqFjVZR2xuvcwnV3a3fdryGsZOzjpJ3wKwNz/SZWRali7ITemTHhlURYlIlBGyVmDPuk25PMX0IGXzFjx+/VyKKiJOOA0qc81WI8gDETBRUHxlqtAd3gxTZwD8asCioujAMyd1RRUWump7vdB8Ki4sb8S8iGSaOo8/uwodpNnl3d70S6wAutH5SDaipg0fRsbd869ToZEaWE6fvK3EBFVCMlsFnJ0K3KZyfw9yMkKhImMCH7CSVRkTHSIHNgACoWTCiQC3UHxrzz7SHUBWFbtWZ8F0aZIlwu8HiziSwTdAAvBJxXs/Qsc63MmMCilrlJnGN+AZ+1EftHvysCAAAAAElFTkSuQmCC"
              alt=""
              style="width: 18px; height: 13px"
            />
            数字人主播忠诚可靠，永不变心
          </p>
        </div></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Input name",
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.priceReviewWarpper {
  height: 940px;
  min-width: 1200px;
  background: #ffffff;
}

.content-title {
  font-size: 48px;
  line-height: 60px;
  padding: 80px 0 32px;
  text-align: center;
  font-weight: 700;
  color: #222831;
}

.content {
  height: 640px;
  .left {
    height: 100%;
    .left_inner {
      overflow: hidden;
      position: relative;
      border-radius: 10px 0 0 10px;
      background: url(https://tc-nj-weta365-pub-cdn.weta365.com/qimiaoyuan-prod/assets/bg.49ddb509.png)
        no-repeat;
      background-position: center center;
      background-size: cover;
      margin: 20px 0;
      padding: 33px 40px 35px;
      .top {
        height: 248px;
        width: 500px;
        img {
          border-radius: 8px;
        }
      }

      .bom {
        margin-top: 30px;
        padding-left: 115px;
        .title {
          margin-bottom: 32px;
          font-size: 28px;
          font-weight: 600;
          color: #222831;
          line-height: 36px;
        }

        .desc {
          margin-bottom: 16px;
          height: 26px;
          font-size: 18px;
          color: #222831;
          line-height: 26px;
          font-weight: 400;
        }

        img {
          height: 14px;
          width: 14px;
          margin-right: 14px;
        }
      }
    }
  }
  .right {
    width: 620px;
    height: 100%;
    background: linear-gradient(226deg, #d9eeff 0%, #c6d8ff 100%);
    border-radius: 10px 24px 24px 10px;
    padding: 36px 40px;

    .top {
      width: 100%;
      height: 251px;
      img {
        border-radius: 8px;
      }
    }
    .bom {
      margin-top: 36px;
      padding-left: 120px;
      .title {
        margin-bottom: 32px;
        font-size: 28px;
        font-weight: 600;
        color: #222831;
        line-height: 36px;
      }

      .desc {
        margin-bottom: 16px;
        height: 26px;
        font-size: 18px;
        font-weight: 500;
        color: #222831;
        line-height: 26px;
        img {
          height: 14px;
          width: 14px;
          margin-right: 14px;
        }
      }
    }
  }
}

.row {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.m-auto {
  margin: 0 auto;
}

.width1200 {
  width: 1200px;
}
</style>

<template>
  <div class="container">
    <div class="main-container">
      <h1 class="main-title">颠覆传统视频制作</h1>
      <div class="content">
        <div class="section traditional">
          <h2>传统视频制作</h2>
          <ul>
            <li>
              <span class="icon">✖</span>
              制作麻烦：一个视频=演员+妆造团队+摄影组+拍摄场地
            </li>
            <li>
              <span class="icon">✖</span> 周期长：制作一个视频的平均时间要4天
            </li>
            <li>
              <span class="icon">✖</span>
              无法修改：一次拍摄，只能用一次，无法修改台词
            </li>
            <li>
              <span class="icon">✖</span>
              模特声音单一：一个模特的声音只能一种，无法切换
            </li>
          </ul>
        </div>
        <div class="section digital">
          <h2>幻腾视频制作</h2>
          <ul>
            <li>
              <span class="icon">✔</span>
              便捷式操作：一个视频=1段文案+1个数字人+一键合成
            </li>
            <li>
              <span class="icon">✔</span> 速度快：制作一个真人口播视频，仅需 5
              分钟
            </li>
            <li>
              <span class="icon">✔</span>
              随时修改：数字人视频，随时修改文本，随时生成
            </li>
            <li>
              <span class="icon">✔</span>
              数字人更智能：一个数字人可说10国语言，500种声音
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoProductionComparison",
}
</script>

<style lang="scss" scoped>
.container {
  height: 729px;
  background-color: #f3f7fd;
  .main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
}

.main-title {
  font-size: 48px;
  line-height: 60px;
  padding: 80px 0 60px;
  text-align: center;
  font-weight: 700;
  color: #222831;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.section {
  flex: 1;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 15px;
      display: flex;
      align-items: flex-start;

      .icon {
        margin-right: 10px;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.traditional {
  // background-color: #f8d7da;
  // color: #721c24;

  .icon {
    color: #dc3545;
  }
}

.digital {
  // background-color: #d4edda;
  // color: #155724;

  background: linear-gradient(218deg, #308dff 0%, #046fff 100%);
  color: #fff;

  .icon {
    color: #28a745;
  }
}
</style>
